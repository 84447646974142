import {
    GET_PACKAGETREATMENTS_STATED,
    GET_PACKAGETREATMENTS,
    GET_PACKAGETREATMENTS_ENDED,
    ADD_PACKAGETREATMENT_STATED,
    ADD_PACKAGETREATMENT,
    ADD_PACKAGETREATMENT_ENDED,
    EDIT_PACKAGETREATMENT_STATED,
    EDIT_PACKAGETREATMENT,
    EDIT_PACKAGETREATMENT_ENDED,
    GET_PACKAGETREATMENT_STATED,
    GET_PACKAGETREATMENT,
    GET_PACKAGETREATMENT_ENDED,
    GET_ALL_PACKAGETREATMENTS_STATED,
    GET_ALL_PACKAGETREATMENTS,
    GET_ALL_PACKAGETREATMENTS_ENDED
} from "../types/packagetreatment_type";

const initialState = {
    packagetreatments_loading: true,
    packagetreatments: null,
    page: null,
    pages: null,
    total_packagetreatments: 0,

    packagetreatment: null,
    packagetreatment_loading: null,

    loading: true,

    packagetreatment_message: null,
    all_packagetreatments: null,
    all_packagetreatments_loading: null,
    add_packagetreatment_loading: true,
    edit_packagetreatment_loading: true
};

export const packagetreatment_reducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case GET_PACKAGETREATMENTS_STATED:
            return {
                ...state,
                packagetreatments: null,
                pages: null,
                page: null,
                total_packagetreatments: 0,
                packagetreatments_loading: true
            };
        case GET_PACKAGETREATMENTS:
            return {
                ...state,
                packagetreatments: payload.packagetreatments,
                pages: payload.pages,
                page: payload.page,
                total_packagetreatments: payload.count
            };
        case GET_PACKAGETREATMENTS_ENDED:
            return {
                ...state,
                packagetreatments_loading: false
            };
        case GET_ALL_PACKAGETREATMENTS_STATED:
            return {
                ...state,
                all_packagetreatments_loading: true,
                all_packagetreatments: null
            };
        case GET_ALL_PACKAGETREATMENTS:
            return {
                ...state,
                all_packagetreatments: payload
            };
        case GET_ALL_PACKAGETREATMENTS_ENDED:
            return {
                ...state,
                all_packagetreatments_loading: false
            };

        case ADD_PACKAGETREATMENT_STATED:
            return {
                ...state,
                packagetreatment_message: null,
                add_packagetreatment_loading: true
            };
        case ADD_PACKAGETREATMENT:
            return {
                ...state,
                packagetreatment_message: payload
            };
        case ADD_PACKAGETREATMENT_ENDED:
            return {
                ...state,
                add_packagetreatment_loading: false
            };
        case GET_PACKAGETREATMENT_STATED:
            return {
                ...state,
                packagetreatment: null,
                packagetreatment_loading: true
            };
        case GET_PACKAGETREATMENT:
            return {
                ...state,
                packagetreatment: payload
            };
        case GET_PACKAGETREATMENT_ENDED:
            return {
                ...state,
                packagetreatment_loading: false
            };
        case EDIT_PACKAGETREATMENT_STATED:
            return {
                ...state,
                packagetreatment_message: null,
                edit_packagetreatment_loading: true
            };
        case EDIT_PACKAGETREATMENT:
            return {
                ...state,
                packagetreatment_message: payload
            };
        case EDIT_PACKAGETREATMENT_ENDED:
            return {
                ...state,
                edit_packagetreatment_loading: false
            };

        default:
            return state;
    }
};
