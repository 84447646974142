import React, { useState } from "react";
import Breadcrumb from "../common/Breadcrumb";
import Footer from "../common/Footer";
import Header from "../common/Header";
import { Formik, Form } from "formik";
import { TextArea, TextInput } from "../../components/Form/Form";
import * as Yup from "yup";
import { useCreateContact } from "../../shared/hooks/UseContact";


function Contact() {

  const [data, addData] = useCreateContact();
  const [showGreeting, setSetshowGreeting] = useState(false)
  return (
    <>
      <Header />
      <Breadcrumb title={"Contact Us"} />
      <div className="section section-padding-02 ">
        <div className="container">
          <div className="contact-form-wrapper mb-5">
            <div className="row flex-row-reverse justify-content-center">
              <div className="col-lg-6 col-md-8 col-sm-10">
                {/* Contact Form Image Start */}
                <div className="contact-us-img">
                  <div className="image">
                    <img src="/assets/contact.jpg" alt="Contact-us" />
                  </div>
                </div>
                {/* Contact Form Image End */}
              </div>
              <div className="col-lg-6">
                {
                  showGreeting ? (
                    <div className='contact-form-area py-150 rpy-100'>
                      <div className="container">
                        <div className='contact-form-wrap wow fadeInUp delay-0-2s'>
                          <div className="success-message-box">
                            <div className="success-message">
                              <h4>Thankyou!</h4>
                              <p>Thankyou for Successfuly filling the form. Our Team will contact you regarding the same</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="contact-form">
                      <h3 className="form-title">Have Question? Write a Message</h3>
                      <Formik
                        initialValues={{
                          first_name: "",
                          last_name: "",
                          email: "",
                          phone: "",
                          message: "",
                        }}
                        validationSchema={Yup.object({
                          first_name: Yup.string().required("Required"),
                          email: Yup.string().email('Invalid email').required('Required'),
                          phone: Yup.string().matches(/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/, 'Phone number is not valid').min(10, 'Must be exactly 10 digit').max(10, 'Must be exactly 10 digit').required("Required"),
                          message: Yup.string().required("Required"),
                        })}
                        onSubmit={async (values, { setSubmitting, resetForm }) => {
                          setSubmitting(true);
                          addData(values);
                          setSetshowGreeting(!showGreeting)
                          resetForm();
                          setSubmitting(false);
                        }}
                      >
                        {(formik) => {
                          console.log(formik);
                          return (
                            <Form>
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="single-form">
                                    <TextInput
                                      name="first_name"
                                      placeholder="First Name"
                                      type="text"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="single-form">
                                    <TextInput
                                      name="last_name"
                                      placeholder="Second Name"
                                      type="text"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-12">
                                  <div className="single-form">
                                    <TextInput
                                      name="email"
                                      placeholder="Email"
                                      type="email"
                                    />
                                  </div>
                                </div>
                                <div className="single-form">
                                  <TextInput
                                    name="phone"
                                    placeholder="Phone"
                                    type="text"
                                  />
                                </div>
                                <div className="single-form">
                                  <TextArea
                                    name="message"
                                    type="text"
                                    rows="1"
                                    cols="100"
                                    placeholder="Message"
                                  />
                                </div>
                                <div className="single-form">
                                  <button
                                    type="submit"
                                    className="btn btn-primary btn-hover-dark w-100"
                                  >
                                    BOOK AN APPOINTMENT
                                  </button>
                                </div>
                              </div>
                            </Form>
                          );
                        }}
                      </Formik>
                    </div>
                  )
                }
              </div>
            </div>
          </div>
          {/* Contact Wrapper Start */}
          <div className="contact-wrapper">
            <div className="row align-items-center">
              {/* Contact Info Start */}
              <div className="contact-info">
                <div className="row">
                  <div className="col-md-7">
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3396.4067345321223!2d74.8599370739067!3d31.650090240724712!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3919656a32fe9acf%3A0x5213906beda989b6!2sCosmetique%20by%20Dr%20Simrat%20Sandhu%20%7C%20Best%20Skin%20Care%20Clinic%20Amritsar%20for%20HydraFacial%2C%20Filler%2C%20Botox%2C%20PRP%20%26%20Laser%20Hair%20Removal!5e0!3m2!1sen!2sin!4v1718359119406!5m2!1sen!2sin  "
                      width="100%"
                      height={450}
                      style={{ border: 0 }}
                      allowFullScreen
                      loading="lazy"
                      referrerPolicy="no-referrer-when-downgrade"
                    />
                  </div>
                  <div className="col-md-5">
                    <h3 className="title">Contact Information</h3>
                    <p className="text">You can connect with us</p>
                    {/* Single Info Start */}
                    <div className="single-info">
                      <h4 className="info-title">Our Address :</h4>
                      <p>
                        Cosmetiquebydrsimratsandhu, Ground floor Holiday Inn Amritsar District Shopping complex, Ranjit Avenue, Amritsar, Punjab 143001
                      </p>
                    </div>
                    <div className="single-info">
                      <h4 className="info-title">Call us</h4>
                      {/* <p>
                        <a href="tel:+1234567890">
                          <strong>Landline :</strong> +01234567890
                        </a>
                      </p> */}
                      <p>
                        <a href="tel:+91-628-325-1847">
                          <strong>Mobile :</strong>+91-628-325-1847
                        </a>
                      </p>
                      <p>
                        <a href="tel:+91-798-665-8380">
                          <strong>Mobile :</strong>+91-798-665-8380
                        </a>
                      </p>
                    </div>
                    {/* Single Info End */}
                  </div>
                </div>
              </div>
              {/* Contact Info End */}

              {/* <div className="contact-info">
                <div className="row">
                  <div className="col-md-5">
                    <div className="single-info">
                      <h4 className="info-title">Sector 45 Branch Address :</h4>
                      <p>
                        Plot no. 42, Ground Floor, Sector 45, Near Hotel Palm
                        Bliss, Gurgaon, Haryana - 122003
                      </p>
                    </div>
                    <div className="single-info">
                      <h4 className="info-title">Call us</h4>
                      <p>
                        <a href="tel:01244277794">
                          <strong>Landline :</strong> 01244277794
                        </a>
                      </p>
                      <p>
                        <a href="tel:9810447794">
                          <strong>Mobile :</strong>9810447794
                        </a>
                      </p>
                    </div>
                    <div className="single-info">
                      <ul className="social">
                        <li>
                          <a href="tel:88268 154422">
                            <i className="fa fa-whatsapp" />
                          </a>
                        </li>
                        <li>
                          <a href="https://www.facebook.com/swakaaya">
                            <i className="fa fa-facebook-f" />
                          </a>
                        </li>

                        <li>
                          <a href="https://www.instagram.com/swakaaya.wellness">
                            <i className="fa fa-instagram" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-7">
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3507.411778884181!2d77.07740921445487!3d28.46714318248329!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d192eeaf58c53%3A0x31f2d47b30e1a400!2sSwakaaya%20Wellness%20and%20Beauty%20Clinic!5e0!3m2!1sen!2sin!4v1669889144832!5m2!1sen!2sin"
                      width="100%"
                      height={450}
                      style={{ border: 0 }}
                      allowFullScreen
                      loading="lazy"
                      referrerPolicy="no-referrer-when-downgrade"
                    />
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Contact;
