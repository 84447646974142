export const GET_PACKAGETREATMENTS_STATED = "GET_PACKAGETREATMENTS_STATED";
export const GET_PACKAGETREATMENTS = "GET_PACKAGETREATMENTS";
export const GET_PACKAGETREATMENTS_ENDED = "GET_PACKAGETREATMENTS_ENDED";
export const ADD_PACKAGETREATMENT_STATED = "ADD_PACKAGETREATMENT_STARTED";
export const ADD_PACKAGETREATMENT = "ADD_PACKAGETREATMENT";
export const ADD_PACKAGETREATMENT_ENDED = "ADD_PACKAGETREATMENT_ENDED";
export const EDIT_PACKAGETREATMENT_STATED = "EDIT_PACKAGETREATMENT_STATED";
export const EDIT_PACKAGETREATMENT = "EDIT_PACKAGETREATMENT";
export const EDIT_PACKAGETREATMENT_ENDED = "EDIT_PACKAGETREATMENT_ENDED";
export const GET_PACKAGETREATMENT = "GET_PACKAGETREATMENT";
export const GET_PACKAGETREATMENT_STATED = "GET_PACKAGETREATMENT_STATED";
export const GET_PACKAGETREATMENT_ENDED = "GET_PACKAGETREATMENT_ENDED";
export const RESET_PACKAGETREATMENT = "RESET_PACKAGETREATMENT";
export const ERROR_PACKAGETREATMENT = "ERROR_PACKAGETREATMENT";
export const GET_ALL_PACKAGETREATMENTS_STATED = "GET_ALL_PACKAGETREATMENTS_STATED";
export const GET_ALL_PACKAGETREATMENTS = "GET_ALL_PACKAGETREATMENTS";
export const GET_ALL_PACKAGETREATMENTS_ENDED = "GET_ALL_PACKAGETREATMENTS_ENDED";
