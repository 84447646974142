export const GET_TECHNOLOGYS_STATED = "GET_TECHNOLOGYS_STATED";
export const GET_TECHNOLOGYS = "GET_TECHNOLOGYS";
export const GET_TECHNOLOGYS_ENDED = "GET_TECHNOLOGYS_ENDED";
export const ADD_TECHNOLOGY_STATED = "ADD_TECHNOLOGY_STARTED";
export const ADD_TECHNOLOGY = "ADD_TECHNOLOGY";
export const ADD_TECHNOLOGY_ENDED = "ADD_TECHNOLOGY_ENDED";
export const EDIT_TECHNOLOGY_STATED = "EDIT_TECHNOLOGY_STATED";
export const EDIT_TECHNOLOGY = "EDIT_TECHNOLOGY";
export const EDIT_TECHNOLOGY_ENDED = "EDIT_TECHNOLOGY_ENDED";
export const GET_TECHNOLOGY = "GET_TECHNOLOGY";
export const GET_TECHNOLOGY_STATED = "GET_TECHNOLOGY_STATED";
export const GET_TECHNOLOGY_ENDED = "GET_TECHNOLOGY_ENDED";
export const RESET_TECHNOLOGY = "RESET_TECHNOLOGY";
export const ERROR_TECHNOLOGY = "ERROR_TECHNOLOGY";
export const GET_ALL_TECHNOLOGYS_STATED = "GET_ALL_TECHNOLOGYS_STATED";
export const GET_ALL_TECHNOLOGYS = "GET_ALL_TECHNOLOGYS";
export const GET_ALL_TECHNOLOGYS_ENDED = "GET_ALL_TECHNOLOGYS_ENDED";
