import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import MobileMenu from './mobilemenu/MobileMenu';
import { useSelectAllCollection } from '../../shared/hooks/UseCollection';
import { useSelectAllTechnology } from '../../shared/hooks/UseTechnology';
import { useSelectAllPackagetreatment } from '../../shared/hooks/UsePackagetreatment';
import { useSelectAllService } from '../../shared/hooks/UseService';

function Header() {
  const [data] = useSelectAllCollection();
  const { all_collections, all_collections_loading } = data;

  const [techData] = useSelectAllTechnology();
  const { all_technologys, all_technologys_loading } = techData;

  const [packagetreatmentData] = useSelectAllPackagetreatment();
  const { all_packagetreatments, all_packagetreatments_loading } =
    packagetreatmentData;

  const [serviceData] = useSelectAllService();
  const { all_services, all_services_loading } = serviceData;

  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener('scroll', () => {
      setScroll(window.scrollY > 50);
    });
  }, []);

  useEffect(() => {
    document.body.style.overflow = 'visible ';
  }, [window.location.href]);
  return (
    <>
      {/* <section className={scroll ? 'top-header fixed' : 'top-header'}>
        <div className='container'>
          <div className='row'>
            <div className='col-xs-6 col-md-8'>
              <h4>
                Book Now: <a href='tel:918826815442'>+91-88268 15442</a>
              </h4>
            </div>
            <div className='col-xs-6 col-md-4'>
              <div className='top-header-social'>
                <ul className=''>
                  <li>
                    <a href='tel:88268 154422'>
                      <i className='fa fa-whatsapp' />
                    </a>
                  </li>
                  <li>
                    <a href='https://www.facebook.com/swakaaya'>
                      <i className='fa fa-facebook-f' />
                    </a>
                  </li>

                  <li>
                    <a href='https://www.instagram.com/swakaaya.wellness'>
                      <i className='fa fa-instagram' />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <div
        id='header'
        className={
          scroll ? 'section header-area fixed ' : 'section header-area'
        }
      >
        <div className='container'>
          <div className='header-wrapper'>
            <div className='header-logo'>
              <Link to='/'>
                <img src='/assets/images/logo.png' width={95} alt='Logo' />
              </Link>
            </div>
            <div className='header-navbar d-none d-lg-block ases'>
              <ul className='navbar-menu'>
                <li>
                  <Link to='/'>Home </Link>
                </li>
                <li>
                  <Link to='/about-us'>About Us </Link>
                </li>

                <li>
                  <a href='/'>Services</a>
                  <span class='menu-icon'></span>
                  <ul class='sub-menu'>
                    {!all_collections_loading &&
                      all_collections &&
                      all_collections.map((collection) => {
                        return (
                          <li>
                            <Link to={`/collection/${collection.slug}`}>
                              {collection.name}
                            </Link>
                            <span class='menu-icon'></span>
                            <ul class='sub-menu'>
                              {all_services &&
                                all_services.filter(
                                  (service) =>
                                    service &&
                                    service.service_collection &&
                                    service.service_collection._id ===
                                      collection._id
                                ) &&
                                all_services.filter(
                                  (service) =>
                                    service &&
                                    service.service_collection &&
                                    service.service_collection._id ===
                                      collection._id
                                ).length > 0 && (
                                  <ul className='sub_menu'>
                                    {all_services &&
                                      all_services.map((service) => {
                                        if (
                                          service &&
                                          service.service_collection &&
                                          service.service_collection._id ===
                                            collection._id
                                        ) {
                                          return (
                                            <li>
                                              <Link
                                                to={`/service/${service.slug}`}
                                              >
                                                {service.name}
                                              </Link>
                                            </li>
                                          );
                                        }
                                      })}
                                  </ul>
                                )}
                            </ul>
                          </li>
                        );
                      })}
                  </ul>
                </li>
                <li>
                  <a href='/'>Packages</a>
                  <span class='menu-icon'></span>
                  <ul class='sub-menu'>
                    {!all_packagetreatments_loading &&
                      all_packagetreatments &&
                      all_packagetreatments.map((packageitem) => {
                        return (
                          <li>
                            {/* <a href={packageitem.slug}>{packageitem.name}</a> */}
                            <Link to={`/packagetreatment/${packageitem.slug}`}>
                              {packageitem.name}
                            </Link>
                          </li>
                        );
                      })}
                  </ul>
                </li>
                <li>
                  <a href='/'>Technologies</a>
                  <span class='menu-icon'></span>
                  <ul className='sub-menu'>
                    {!all_technologys_loading &&
                      all_technologys &&
                      all_technologys.map((item) => {
                        return (
                          <li>
                            {/* <Link to={item.slug}>{item.name}</Link> */}
                            <Link to={`/technology/${item._id}`}>
                              {item.name}
                            </Link>
                          </li>
                        );
                      })}
                  </ul>
                </li>
                <li>
                  <Link to='/contact-us'>Contact Us </Link>
                </li>
              </ul>
            </div>
            <div className='appi'>
              <a
                href='#'
                className='appoin'
                data-bs-toggle='modal'
                data-bs-target='#staticBackdrop'
              >
                Book an Appointment
              </a>
            </div>
            <div class='header-meta'>
              <div class='header-toggle d-lg-none'>
                <button
                  class='toggle'
                  data-bs-toggle='offcanvas'
                  data-bs-target='#offcanvasNavbar'
                >
                  <span></span>
                  <span></span>
                  <span></span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <MobileMenu />
    </>
  );
}

export default Header;
