import React from 'react';
import Banner from '../../components/banner/Banner';
import Services from '../services/Services';
import About from '../../components/about/About';
import ServiceSection from '../../components/servicesection/ServiceSection';
import WhyChooseUs from '../../components/whychooseus/WhyChooseUs';
import Testimonial from '../../components/testimonial/Testimonial';
import Blog from '../../components/blog/Blog';
import Footer from '../common/Footer';
import Header from '../common/Header';
import BeforeAfter from '../../components/beforeafter/BeforeAfter';
import Collection from '../../components/collection/Collection';
import { Helmet } from 'react-helmet';

function Home() {
  return (
    <div>
      <Helmet>
        <meta charSet='utf-8' />
        <title itemProp='name'>
          Best Skincare and Aesthetic Treatments in Amritsar
        </title>
        <meta
          name='description'
          content="Locate the best-class skincare and aesthetic treatments at Dr. Simrat Sandhu's clinic in Amritsar, an epitome for the best treatments like laser hair removal, PRP, Botox, and many more."
        />
        <meta
          name='keywords'
          content='skin doctor in Amritsar,
best skin doctors in Amritsar,
skin care clinic near me,
Cosmotologist near me
'
        />

        <meta
          property='og:title'
          content='best skincare and aesthetic treatments in amritsar'
        />
        <meta property='og:type' content='aesthetic clinic' />
        <meta property='og:url' content='https://www.drsimratsandhu.in/' />
        <link rel='canonical' href='https://www.drsimratsandhu.in/' />
      </Helmet>
      <div className='main-wrapper'>
        {/* Header Start */}
        <Header />
        <Banner />
        <Collection />
        <About />
        <ServiceSection />
        <WhyChooseUs />
        <BeforeAfter />
        {/* <OfferSection /> */}
        <Testimonial />
        <Blog />
        <Footer />
      </div>
    </div>
  );
}

export default Home;
