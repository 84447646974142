import React from 'react';
import { useSelectAllBlog } from '../../shared/hooks/UseBlog';
import { URI } from '../../domain/constant';
import moment from 'moment';
import BlogCard from '../home/BlogCard';

function Blog() {

  const [data] = useSelectAllBlog();
  const { all_blogs, all_blogs_loading } = data;

  console.log("data - ", data);

  return (
    <div className='section section-padding'>
      <div className='container'>
        {/* Blog Wrapper Start */}
        <div className='blog-wrapper'>
          <div className='row'>
            <div className='col-lg-4' data-aos='fade-up' data-aos-delay={200}>
              {/* Blog Sidebar Title Start */}
              <div className='blog-sidebar-title'>
                {/* Section Title Start */}
                <div className='section-title'>
                  <h6 className='sub-title'>Blog</h6>
                  <h2 className='title'>
                    Latest Post <br />
                    from Blog
                    <img
                      className='shape'
                      src='assets/images/shape/shape-15.webp'
                      alt='Shape'
                    />
                  </h2>
                </div>
                {/* Section Title End */}
                {/* <p class="text">
              Deep discuss about Skin Treatment and Therapy Treamment
            </p> */}
              </div>
              {/* Blog Sidebar Title End */}
            </div>
            <div className="col-md-8">
              <div className="blog-group">
                <div className="row">
                  {
                    all_blogs && all_blogs.map((blog, index) => {
                      if (index < 2) {
                        return (
                          <div className="col-md-6">
                            <BlogCard
                              image={`${URI}${blog.image}`}
                              date={moment(blog.createdAt).format('DD/MM/YYYY')}
                              title={blog.title}
                              link={blog.slug}
                            />
                          </div>
                        )
                      }

                    })
                  }
                </div>
              </div>
            </div>
            <div
              data-aos='fade-up'
              data-aos-delay={400}
            >

            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Blog;
