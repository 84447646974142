import React from 'react'
import Header from '../common/Header'
import Footer from '../common/Footer'
import Breadcrumb from '../common/Breadcrumb'
import { useSingleBlogBySlug, useSelectAllBlog } from '../../shared/hooks/UseBlog'
import { URI } from '../../domain/constant'
import renderHTML from 'react-render-html'

function SingleBlog({ match }) {

    const [data] = useSingleBlogBySlug(match.params.slug);
    const { blog, blog_loading } = data;

    const [blog_data] = useSelectAllBlog();
    const { all_blogs, all_blogs_loading } = blog_data;
    return (
        <>
            <Header />
            {
                !blog_loading ? blog && (
                    <>
                        <Breadcrumb title={blog.title} />
                        <section className="collection-page section section-padding about-section">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-9 mx-auto">
                                        <div className="collection-featured-img">
                                            <img src={`${URI}${blog.image}`} alt="" />
                                            {/* <img src={`/assets/images/test_2.jpg`} alt="" /> */}
                                        </div>
                                        <div className="service-top-section-content pt-18">
                                            <div className="service-top-section-heading">
                                                <h1>{blog.title} </h1>
                                            </div>
                                            <div className="service-top-section-para">
                                                <p>
                                                    {blog && blog.description && renderHTML(blog.description)}
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                </div >
                            </div >
                        </section >
                    </>

                ) : (
                    <div></div>
                )
            }


            <Footer />
        </>
    )
}

export default SingleBlog