import React from 'react'
import Header from '../common/Header'
import Breadcrumb from '../common/Breadcrumb'
import Footer from '../common/Footer'
import { Link } from 'react-router-dom'
import BlogCard from '../../components/home/BlogCard'
import { useSelectAllBlog } from '../../shared/hooks/UseBlog';
import { URI } from '../../domain/constant';
import moment from 'moment';

function Blog() {
    const [data] = useSelectAllBlog();
    const { all_blogs, all_blogs_loading } = data;
    return (
        <>
            <Header />
            <Breadcrumb title={"Our Blogs"} />
            <section className="blogs">
                <div className="container">
                    <div className="row">
                        {
                            all_blogs && all_blogs.map((blog) => {
                                return (
                                    <div className="col-md-4">
                                        <div className="blog-cards-group mt-20">
                                            <BlogCard
                                                image={`${URI}${blog.image}`}
                                                date={moment(blog.createdAt).format('DD/MM/YYYY')}
                                                title={blog.title}
                                                link={blog.slug}
                                            />
                                        </div>
                                    </div>
                                )
                            })
                        }

                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default Blog