import React from 'react';

function WhyChooseUs() {
  return (
    <div className='section section-padding'>
      <div className='container'>
        {/* Section Title Start */}
        <div className='section-title text-center'>
          {/* <h6 class="sub-title">Good For Health</h6> */}
          <h2 className='title'>WHY CHOOSE US?</h2>
          <p>
            When it comes to improving your natural beauty and preserving youthful, glowing skin, selecting the correct provider is essential.
          </p>
        </div>
        {/* Section Title End */}
        {/* Choose Wrapper Start */}
        <div className='choose-wrapper'>
          <div className='row justify-content-center align-items-center gx-lg-0'>
            <div className='col-lg-3 col-sm-6 order-2 order-lg-1'>
              {/* Single Choose Start */}
              <div
                className='single-choose'
                data-aos='fade-up'
                data-aos-delay={100}
              >
                <h4 className='title'>Expertise and Experience</h4>
                <p>
                  Our team consists of highly trained, board-certified Cosmetic Physician and aesthetic professionals with years of experience in the field.
                </p>
              </div>
              <div
                className='single-choose'
                data-aos='fade-up'
                data-aos-delay={100}
              >
                <h4 className='title'>Customized Treatment Plans</h4>
                <p>
                  We understand that every individual’s skin is unique. That’s why we create personalized treatment plans tailored to your specific needs and goals.
                </p>
              </div>
              {/* Single Choose End */}
              {/* Single Choose Start */}
              <div
                className='single-choose'
                data-aos='fade-up'
                data-aos-delay={200}
              >
                <h4 className='title'>State-of-the-Art Technology</h4>
                <p>
                  We use the latest, most advanced technology and techniques in our treatments, including the highly effective DermaFuse system and other innovative solutions.
                </p>
              </div>
              {/* Single Choose End */}
              {/* Single Choose Start */}

              {/* Single Choose End */}
            </div>
            <div className='col-lg-6 order-1 col-md-8 order-lg-2'>
              {/* Choose Image Start */}
              <div
                className='choose-image'
                data-aos='fade-up'
                data-aos-delay={200}
              >
                <div className='image'>
                  <img src='/assets/Home_Image_1-removebg-preview.png' alt='Choose' />
                </div>
              </div>
              {/* Choose Image End */}
            </div>
            <div className='col-lg-3 col-sm-6 order-3 order-lg-3'>
              {/* Single Choose Start */}
              <div
                className='single-choose'
                data-aos='fade-up'
                data-aos-delay={100}
              >
                <h4 className='title'>Positive Reviews and Testimonials</h4>
                <p>
                  Our satisfied patients speak for themselves. We have a track record of positive reviews and testimonials, highlighting our commitment to excellence and patient satisfaction.
                </p>
              </div>
              <div
                className='single-choose'
                data-aos='fade-up'
                data-aos-delay={300}
              >
                <h4 className='title'>Comprehensive Services</h4>
                <p>
                  From Botox and fillers to laser therapy and skin tightening, we offer a wide range of services to address all your aesthetic concerns.
                </p>
              </div>
              {/* Single Choose End */}
              {/* Single Choose Start */}
              <div
                className='single-choose'
                data-aos='fade-up'
                data-aos-delay={200}
              >
                <h4 className='title'>Friendly and Supportive Staff</h4>
                <p>
                  Our dedicated team is committed to making your experience as comfortable and stress-free as possible, from the moment you walk through our doors.
                </p>
              </div>
              {/* Single Choose End */}

              {/* Single Choose End */}
            </div>
          </div>
        </div>
        {/* Choose Wrapper End */}
      </div>
    </div>
  );
}

export default WhyChooseUs;
