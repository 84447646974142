import React from "react";
import { Link } from "react-router-dom";

function Breadcrumb({ title }) {
  return (
    <div
      className="section page-banner"
      style={{ backgroundImage: "url(/assets/images/page_banner.jpg)" }}
    >
      <div className="container">
        {/* Page Banner Content Start */}
        <div className="page-banner-content">
          <h2 className="title">{title}</h2>

          <ul className="breadcrumb">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/">{title}</Link>
            </li>
          </ul>
        </div>
        {/* Page Banner Content End */}
      </div>
    </div>
  );
}

export default Breadcrumb;
