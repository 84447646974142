import React from 'react'
import { Link } from "react-router-dom";


function CollectionCard({ image, name, link, description }) {
    return (
        <div className="collection-card">
            <div className="collection-inside-box">
                <div className="collection-card-img">
                    <img src={image} alt="" />
                </div>
                <div className="collection-card-content pt-20">
                    <div className="collection-card-title">
                        <Link to={link}>
                            <h3 className='title'>{name}</h3>
                        </Link>
                    </div>
                    <div className="collection-card-short-sec">
                        <p>
                            {description}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CollectionCard