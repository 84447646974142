import React, { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import { TextArea, TextInput } from '../../components/Form/Form';
import * as Yup from 'yup';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useSelectAllCollection } from '../../shared/hooks/UseCollection';
import { useSelectAllService } from '../../shared/hooks/UseService';
import { useCreateAppointment } from '../../shared/hooks/UseAppointment';
import { useCreateNewsletter } from '../../shared/hooks/UseNewsletter';

function Footer() {
  const [data] = useSelectAllCollection();
  const { all_collections } = data;

  const [serviceData] = useSelectAllService();
  const { all_services } = serviceData;

  const [appointmentData, addData] = useCreateAppointment();
  const [newsletterData, addNewslatterData] = useCreateNewsletter();
  const [showGreeting, setSetshowGreeting] = useState(false)

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className='section section-padding-02'>
        <div className='container'>
          <div className='brand-wrapper brand-active'>
            <div className='swiper-container'>
              <div className='swiper-wrapper'>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='footer-section section'>
        {/* Footer Top Start */}
        <div className='footer-top'>
          <img
            className='shape-1 movebounce-01'
            src='/assets/images/shape/shape-16.webp'
            width={185}
            height={231}
            alt='Shape'
          />

          <img
            className='shape-3 movebounce-01'
            src='/assets/images/shape/shape-18.webp'
            width={503}
            height={287}
            alt='Shape'
          />
          <div className='container'>
            {/* Newsletter Wrapper Start */}
            <div
              className='newsletter-wrapper text-center'
              data-aos='fade-up'
              data-aos-delay={200}
            >
              <a className='logo' href='#'>
                <img
                  src='/assets/images/logo.png'
                  width={172}
                  height={45}
                  alt='Logo'
                />
              </a>
              <h4 className='title'>SUBSCRIBE &amp; ENJOY</h4>
              <p>
                Get the latest updates &amp; offers by subscribing to our
                newsletter!
              </p>
              {
                showGreeting ? (
                  <div className='contact-form-area py-150 rpy-100'>
                    <div className="container">
                      <div className='contact-form-wrap wow fadeInUp delay-0-2s'>
                        <div className="success-message-box">
                          <div className="success-message">
                            <h4>Thankyou!</h4>
                            <p>Thankyou for Successfuly filling the form. Our Team will contact you regarding the same</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="newsletter-input-box mt-5">
                    <Formik
                      initialValues={{
                        email: "",
                      }}
                      validationSchema={Yup.object({
                        email: Yup.string().email('Invalid email').required('Required')
                      })}
                      onSubmit={async (values, { setSubmitting, resetForm }) => {
                        setSubmitting(true);
                        addNewslatterData(values);
                        setSetshowGreeting(!showGreeting)
                        resetForm();
                        setSubmitting(false);
                      }}
                    >
                      {(formik) => {
                        console.log(formik);
                        return (
                          <Form>
                            <div className="row">
                              <div className="col-sm-6  mx-auto">
                                <div className="newsletter-form-inpu newsletter-formt">
                                  <TextInput
                                    type="text"
                                    id="email"
                                    name="email"
                                    className="form-control "
                                    defaultValue placeholder="Enter Your Email Here" />
                                </div>
                              </div>
                            </div>
                            <div className="form-body">
                              <div className="newsletter-form-btn">
                                <button
                                  type="submit"
                                  className=" btn-primary btn-hover-dark"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </Form>
                        );
                      }}
                    </Formik>
                  </div>
                )

              }
              {/* <div className='newsletter-form'>
                <form action='#'>
                  <input type='text' placeholder='Enter your email here' />
                  <button className='btn btn-white btn-hover-dark'>Send</button>
                </form>
              </div> */}
            </div>
            {/* Newsletter Wrapper End */}
            {/* Footer Widget Wrapper Start */}
            <div className='footer-widget-wrapper'>
              <div className='row'>
                <div className='col-lg-3 col-sm-6'>
                  {/* Footer Widgte Start */}
                  <div
                    className='footer-widget'
                    data-aos='fade-up'
                    data-aos-delay={150}
                  >
                    <h4 className='widget-title'>Contact Info</h4>
                    {/* Widgte Info Start */}
                    <div className='footer-widget-info'>
                      <div className='single-widget-info'>
                        <h6 className='title'>Address :</h6>
                        <p>
                          Cosmetiquebydrsimratsandhu, Ground floor Holiday Inn Amritsar District Shopping Complex, Ranjit Avenue, Amritsar, Punjab 143001
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-lg-3 col-sm-6'>
                  <div
                    className='footer-widget'
                    data-aos='fade-up'
                    data-aos-delay={300}
                  >
                    <h4 className='widget-title '>Quick Links</h4>
                    <div className='footer-widget-link'>
                      <ul>
                        <li>
                          <Link to='/about-us'>About us</Link>
                        </li>
                        <li>
                          <Link to='/collections'>Categories</Link>
                        </li>
                        <li>
                          <Link to='/packages'>Packages </Link>
                        </li>
                        <li>
                          <Link to='/technologies'>Technologies </Link>
                        </li>
                        <li>
                          <Link to='/services'>Services </Link>
                        </li>
                        <li>
                          <Link to='/blogs'>Blogs</Link>
                        </li>
                        <li>
                          <Link to='/contact-us'>Contact Us </Link>
                        </li>

                      </ul>
                    </div>
                    <h4 className='widget-title mt-2'>Services Category</h4>
                    <div className='footer-widget-link'>
                      <ul>
                        {all_collections && all_collections.map((collection) => {
                          return (
                            <li>
                              <Link to={`/collection/${collection.slug}`}>{collection.name}</Link>
                            </li>
                          )
                        })}

                      </ul>
                    </div>
                  </div>
                </div>
                <div className='col-lg-3 col-sm-6'>
                  <div
                    className='footer-widget'
                    data-aos='fade-up'
                    data-aos-delay={450}
                  >
                    <h4 className='widget-title'>Explore</h4>
                    {/* Widgte Link Start */}
                    <div className='footer-widget-link'>
                      <ul>
                        {
                          all_services && all_services.map((service, index) => {
                            if (index < 12) {
                              return (
                                <li className='text-trans-capital'>
                                  <Link to={`/service/${service.slug}`}>{service.name}</Link>

                                </li>
                              )
                            }

                          })
                        }


                      </ul>
                    </div>
                    {/* Widgte Link End */}
                  </div>
                  {/* Footer Widgte End */}
                </div>
                <div className='col-lg-3 col-sm-6'>
                  {/* Footer Widgte Start */}
                  <div
                    className='footer-widget'
                    data-aos='fade-up'
                    data-aos-delay={600}
                  >
                    <h4 className='widget-title'>Opening Hour</h4>
                    {/* Widgte Info Start */}
                    <div className='footer-widget-info'>
                      <div className='single-widget-info'>
                        {/* <h6 className='title'>Lorem, ipsum dolor.</h6> */}
                        <p>Summers: Mon &ndash; Sat 11:00 AM to 7:00 PM Winters: Mon &ndash; Sat 11:00 AM to 6:00 PM Sun &ndash; 11:30 AM to 3:30 PM (Only prepaid appointments)</p>
                      </div>
                      {/* <div className='single-widget-info'>
                        <h6 className='title'>Monday </h6>
                        <p>Closed</p>
                      </div> */}
                      <div className='single-widget-info'>
                        <h6 className='title'>Mail</h6>
                        <p>
                          <a href='mailto:drsimratsandhu@gmail.com'>
                            drsimratsandhu@gmail.com
                          </a>
                        </p>
                      </div>
                    </div>
                    {/* Widgte Info End */}
                    {/* Widgte Book End */}
                    <div className='footer-widget-book'>
                      <h5 className='book-title'>Book Now!</h5>
                      <p>
                        <i className='fa fa-phone'></i>
                        <a href='tel:+91-628-325-1847'>+91-628-325-1847</a>
                      </p>
                      <p>
                        <i className='fa fa-phone'></i>
                        <a href='tel:+91-798-665-8380'>+91-798-665-8380</a>
                      </p>
                    </div>
                    <div className='single-info'>
                      <ul className='social'>
                        <li>
                          <a href='tel:7986648380'>
                            <i className='fa fa-whatsapp' />
                          </a>
                        </li>
                        <li>
                          <a href='https://www.facebook.com/cosmetiquetheaestheticcentre' target='_blank'>
                            <i className='fa fa-facebook-f' />
                          </a>
                        </li>

                        <li>
                          <a href='https://www.instagram.com/cosmetiquebydrsimratsandhu/' target='_blank'>
                            <i className='fa fa-instagram' />
                          </a>
                        </li>
                        <li>
                          <a href='https://www.youtube.com/@cosmetiquebydrsimratsandhu/' target='_blank'>
                            <i className='fa fa-youtube' />
                          </a>
                        </li>
                      </ul>
                    </div>

                    {/* Widgte Book End */}
                  </div>
                  {/* Footer Widgte End */}
                </div>
              </div>
            </div>
            {/* Footer Widget Wrapper End */}
          </div>
          <div>
            <div className='fixed'>
              <div className='test mt-5' style={{ right: 0, bottom: '10%' }}>
                <a
                  href='tel:7986648380'
                  target='_blank'
                >
                  {/* <i className='fa fa-phone'> </i> */}
                  <img src="/assets/phone-call-footer.png" alt="" />
                </a>
              </div>
            </div>
            <div className='fixed'>
              <div className='test mt-5' style={{ left: 0, bottom: '10%' }}>
                <a
                  href='https://wa.me/917986648380'
                  target='_blank'
                  style={{ color: '#25d366', fontSize: '32px', fontWeight: '600' }}
                >
                  {/* <i className='fa fa-whatsapp'> </i> */}
                  <img src="/assets/whatsapp_footer.png" alt="" />
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* Footer Top End */}
        {/* Footer Copyright Start */}
        <div className='footer-copyright'>
          <div className='container'>
            {/* Copyright Wrapper Start */}
            <div className='copyright-wrapper flex-row-reverse'>
              {/* <div className='payment-method'>
                <ul className='payment-icon'>
                  <li>
                    <img
                      src='/assets/images/payment-icon/icon-1.webp'
                      height={15}
                      alt='Icon'
                    />
                  </li>
                  <li>
                    <img
                      src='/assets/images/payment-icon/icon-2.webp'
                      height={15}
                      alt='Icon'
                    />
                  </li>
                  <li>
                    <img
                      src='/assets/images/payment-icon/icon-3.webp'
                      height={15}
                      alt='Icon'
                    />
                  </li>
                  <li>
                    <img
                      src='/assets/images/payment-icon/icon-4.webp'
                      height={15}
                      alt='Icon'
                    />
                  </li>
                </ul>
              </div> */}

              <div className='copyright-text text-center'>
                <p>Copyright © Dr. Simrat Sandhu  All Rights Reserved.</p>
              </div>
            </div>
            {/* Copyright Wrapper End */}
          </div>
        </div>
        {/* Footer Copyright End */}
      </div>
      <button id='backBtn' className='back-to-top'>
        <i className='icofont-simple-up' />
      </button>
      {/*Back To End*/}
      {/* popup */}
      <div>
        <div
          className='modal fade'
          id='staticBackdrop'
          data-bs-backdrop='static'
          data-bs-keyboard='false'
          tabIndex={-1}
          aria-labelledby='staticBackdropLabel'
          aria-hidden='true'
        >
          <div className='modal-dialog'>
            <div className='modal-content'>
              <div className='modal-header'>
                <h3 class='title'>
                  Request a <span>Call Back</span>
                </h3>
                <button
                  type='button'
                  className='btn-close'
                  data-bs-dismiss='modal'
                  aria-label='Close'
                />
              </div>
              <div className='modal-body'>
                {
                  showGreeting ? (
                    <div className='contact-form-area py-150 rpy-100'>
                      <div className="container">
                        <div className='contact-form-wrap wow fadeInUp delay-0-2s'>
                          <div className="success-message-box">
                            <div className="success-message">
                              <h4>Thankyou!</h4>
                              <p>Thankyou for Successfuly filling the form. Our Team will contact you regarding the same</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div class='register-login-form'>
                      <div className='form-wrapper'>
                        <Formik
                          initialValues={{
                            name: '',
                            email: '',
                            phone: '',
                            message: '',
                          }}
                          validationSchema={Yup.object({
                            name: Yup.string().required('Required'),
                            email: Yup.string().required('Required'),
                            phone: Yup.string().required('Required'),
                          })}
                          onSubmit={async (
                            values,
                            { setSubmitting, resetForm }
                          ) => {
                            // setSubmitting(true);
                            // await axios.get(
                            //   `https://thisistest.luhaifdigitech.com/mail.php?name=${values.name}&email=${values.email}&phone=${values.phone}&message=${values.message}`
                            // );
                            // resetForm();
                            // setSubmitting(false);

                            setSubmitting(true);
                            addData(values);
                            setSetshowGreeting(!showGreeting)
                            resetForm();
                            setSubmitting(false);
                          }}
                        >
                          {(formik) => {
                            console.log(formik);
                            return (
                              <Form>
                                <div className='single-form'>
                                  <TextInput
                                    name='name'
                                    placeholder='Name'
                                    type='text'
                                  />
                                </div>
                                <div className='single-form'>
                                  <TextInput
                                    name='email'
                                    placeholder='Email'
                                    type='email'
                                  />
                                </div>
                                <div className='single-form'>
                                  <TextInput
                                    name='phone'
                                    placeholder='Phone'
                                    type='text'
                                  />
                                </div>
                                <div className='single-form'>
                                  <TextArea
                                    name='message'
                                    type='text'
                                    rows='1'
                                    cols='100'
                                    placeholder='Message'
                                  />
                                </div>
                                <div className='single-form'>
                                  <button
                                    type='submit'
                                    className='btn btn-primary btn-hover-dark w-100'
                                  >
                                    BOOK AN APPOINTMENT
                                  </button>
                                </div>
                              </Form>
                            );
                          }}
                        </Formik>
                      </div>
                    </div>
                  )
                }

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
