import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import { useSelectAllTestimonial } from '../../shared/hooks/UseTestimonial';
import TestimonialCard from '../home/TestimonialCard';

function Testimonial() {

  const [data] = useSelectAllTestimonial();
  const { all_testimonials, all_testimonials_loading } = data;

  console.log("all_testimonials -", all_testimonials);

  return (
    <div className='section section-padding testimonial-section'>
      {/* <img
        class="shape-1 movebounce-03"
        src="assets/images/shape/shape-14.webp"
        width="212"
        height="191"
        alt="Shape"
      /> */}
      <div className='container'>
        {/* Section Title Start */}
        <div className='section-title text-center'>
          <h6 className='sub-title'>CLIENT TESTIMONALS</h6>
          <h2 className='title'>WHAT OUR CLIENTS SAY</h2>
          <p />
        </div>
        {/* Section Title End */}
        {/* Testimonial Wrapper Start */}
        <div
          className='testimonial-wrapper testimonial-active'
          data-aos='fade-up'
          data-aos-delay={200}
        >
          <div className='row row-cols-12 row-cols-lg-12'>
            <div className=''>
              <div className='swiper-container'>
                <Swiper
                  modules={[Pagination]}
                  spaceBetween={50}
                  autoplay={200}
                  pagination={{ clickable: true }}
                  scrollbar={{ draggable: true }}
                  slidesPerView={2}
                  onSlideChange={() => console.log('slide change')}
                  onSwiper={(swiper) => console.log(swiper)}
                >
                  {
                    all_testimonials && all_testimonials.map((testimonial) => {
                      return (
                        <SwiperSlide>
                          <TestimonialCard
                            testimonial={testimonial.testimonial}
                            name={testimonial.name}
                          />
                        </SwiperSlide>
                      )
                    })
                  }


                  <div className='swiper-button-next' />
                  <div className='swiper-button-prev' />
                  <div className='swiper-pagination' />
                </Swiper>
              </div>
            </div>
          </div>
        </div>
        {/* Testimonial Wrapper End */}
      </div>
    </div>
  );
}

export default Testimonial;
