import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper';

function BeforeAfter() {
    return (
        <div>
            <section className='section section-padding-02 before-after-section'
                data-aos='fade-up'
                data-aos-delay={200}
                style={{ backgroundImage: 'url(assets/images/patan-bg.webp)' }}
            >
                <div className="container">
                    <div className='section-title text-center'>
                        {/* <h6 className='sub-title'>Lorem ipsum dolor sit amet consectetur adipisicing elit.</h6> */}
                        <h2 className='title'>BEFORE AFTER</h2>
                        <p />
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className='services-active'>
                                <div className='swiper-container'>
                                    <Swiper
                                        modules={[Pagination]}
                                        spaceBetween={30}
                                        pagination={{ clickable: true }}
                                        scrollbar={{ draggable: true }}
                                        slidesPerView={2}
                                        onSlideChange={() => console.log('slide change')}
                                        onSwiper={(swiper) => console.log(swiper)}
                                    >
                                        <SwiperSlide>
                                            <div className='swiper-slide'>
                                                {/* Services Start */}
                                                <div className='text-center'>
                                                    <div className='services-image'>
                                                        <a href='/'>
                                                            <img
                                                                src='/assets/before_after/ba_1.png'
                                                                width={500}
                                                                height={500}
                                                                alt='services'
                                                            />
                                                        </a>
                                                    </div>
                                                    <div className='services-content pt-2'>
                                                        <h4 className='title'>
                                                            {/* <a href='/'>Acne Buster Treatment</a> */}
                                                        </h4>
                                                        {/* <p>Lorem ipsum dolor, sit amet</p> */}
                                                    </div>
                                                </div>
                                                {/* Services End */}
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className='swiper-slide'>
                                                {/* Services Start */}
                                                <div className='text-center'>
                                                    <div className='services-image'>
                                                        <a href='/'>
                                                            <img
                                                                src='/assets/before_after/ba_2.png'
                                                                width={500}
                                                                height={500}
                                                                alt='services'
                                                            />
                                                        </a>
                                                    </div>
                                                    <div className='services-content pt-2'>
                                                        <h4 className='title'>
                                                            {/* <a href='/'>Treatment Name</a> */}
                                                        </h4>
                                                        {/* <p>Lorem ipsum dolor, sit amet</p> */}
                                                    </div>
                                                </div>
                                                {/* Services End */}
                                            </div>
                                        </SwiperSlide>

                                        <SwiperSlide>
                                            <div className='swiper-slide'>
                                                {/* Services Start */}
                                                <div className='text-center'>
                                                    <div className='services-image'>
                                                        <a href='/'>
                                                            <img
                                                                src='/assets/before_after/ba_3.png'
                                                                width={500}
                                                                height={500}
                                                                alt='services'
                                                            />
                                                        </a>
                                                    </div>
                                                    <div className='services-content pt-2'>
                                                        <h4 className='title'>
                                                            {/* <a href='/'>Treatment Name</a> */}
                                                        </h4>
                                                    </div>
                                                </div>
                                                {/* Services End */}
                                            </div>
                                        </SwiperSlide>

                                        <div className='swiper-pagination' />
                                    </Swiper>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </div>
    )
}

export default BeforeAfter