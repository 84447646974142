import React from 'react'

function TestimonialCard({ testimonial, name }) {
    return (
        <div>
            <div className='swiper-slide single-testimonial'>
                <div className='testimonial-content'>
                    {/* <p>
                              I visited
                              <strong> Swakaaya Wellness Clinic</strong> for hair
                              loss treatment, and I’m satisfied with the results, my
                              treatment is still going on. Thank you, team!
                            </p> */}
                    <p>
                        {testimonial}
                    </p>
                    <h4 className='name'>{name}</h4>
                    <div className='rating'>
                        <div
                            className='rating-star'
                            style={{ width: '100%' }}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TestimonialCard