import {
    GET_TECHNOLOGYS_STATED,
    GET_TECHNOLOGYS,
    GET_TECHNOLOGYS_ENDED,
    ADD_TECHNOLOGY_STATED,
    ADD_TECHNOLOGY,
    ADD_TECHNOLOGY_ENDED,
    EDIT_TECHNOLOGY_STATED,
    EDIT_TECHNOLOGY,
    EDIT_TECHNOLOGY_ENDED,
    GET_TECHNOLOGY_STATED,
    GET_TECHNOLOGY,
    GET_TECHNOLOGY_ENDED,
    GET_ALL_TECHNOLOGYS_STATED,
    GET_ALL_TECHNOLOGYS,
    GET_ALL_TECHNOLOGYS_ENDED
} from "../types/technology_type";

const initialState = {
    technologys_loading: true,
    technologys: null,
    page: null,
    pages: null,
    total_technologys: 0,

    technology: null,
    technology_loading: null,

    loading: true,

    technology_message: null,
    all_technologys: null,
    all_technologys_loading: null,
    add_technology_loading: true,
    edit_technology_loading: true
};

export const technology_reducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case GET_TECHNOLOGYS_STATED:
            return {
                ...state,
                technologys: null,
                pages: null,
                page: null,
                total_technologys: 0,
                technologys_loading: true
            };
        case GET_TECHNOLOGYS:
            return {
                ...state,
                technologys: payload.technologys,
                pages: payload.pages,
                page: payload.page,
                total_technologys: payload.count
            };
        case GET_TECHNOLOGYS_ENDED:
            return {
                ...state,
                technologys_loading: false
            };
        case GET_ALL_TECHNOLOGYS_STATED:
            return {
                ...state,
                all_technologys_loading: true,
                all_technologys: null
            };
        case GET_ALL_TECHNOLOGYS:
            return {
                ...state,
                all_technologys: payload
            };
        case GET_ALL_TECHNOLOGYS_ENDED:
            return {
                ...state,
                all_technologys_loading: false
            };

        case ADD_TECHNOLOGY_STATED:
            return {
                ...state,
                technology_message: null,
                add_technology_loading: true
            };
        case ADD_TECHNOLOGY:
            return {
                ...state,
                technology_message: payload
            };
        case ADD_TECHNOLOGY_ENDED:
            return {
                ...state,
                add_technology_loading: false
            };
        case GET_TECHNOLOGY_STATED:
            return {
                ...state,
                technology: null,
                technology_loading: true
            };
        case GET_TECHNOLOGY:
            return {
                ...state,
                technology: payload
            };
        case GET_TECHNOLOGY_ENDED:
            return {
                ...state,
                technology_loading: false
            };
        case EDIT_TECHNOLOGY_STATED:
            return {
                ...state,
                technology_message: null,
                edit_technology_loading: true
            };
        case EDIT_TECHNOLOGY:
            return {
                ...state,
                technology_message: payload
            };
        case EDIT_TECHNOLOGY_ENDED:
            return {
                ...state,
                edit_technology_loading: false
            };

        default:
            return state;
    }
};
