import api from "../../domain/api";
import {
    GET_PACKAGETREATMENTS_STATED,
    GET_PACKAGETREATMENTS,
    GET_PACKAGETREATMENTS_ENDED,
    ADD_PACKAGETREATMENT_STATED,
    ADD_PACKAGETREATMENT,
    ADD_PACKAGETREATMENT_ENDED,
    EDIT_PACKAGETREATMENT_STATED,
    EDIT_PACKAGETREATMENT,
    EDIT_PACKAGETREATMENT_ENDED,
    GET_PACKAGETREATMENT_STATED,
    GET_PACKAGETREATMENT,
    GET_PACKAGETREATMENT_ENDED,
    GET_ALL_PACKAGETREATMENTS_STATED,
    GET_ALL_PACKAGETREATMENTS,
    GET_ALL_PACKAGETREATMENTS_ENDED,
} from "../types/packagetreatment_type";
import * as qs from "qs";
import { handleError } from "../../shared/handleError";
import { setAlert } from "./alert";

export const addPackagetreatment = (formData) => async (dispatch, getState) => {
    try {
        dispatch({
            type: ADD_PACKAGETREATMENT_STATED,
        });
        const { data } = await api.post(`/packagetreatments`, formData);
        dispatch({
            type: ADD_PACKAGETREATMENT,
            payload: data,
        });
        dispatch({
            type: ADD_PACKAGETREATMENT_ENDED,
        });
    } catch (error) {
        dispatch({
            type: ADD_PACKAGETREATMENT_ENDED,
        });
        dispatch(handleErrorLocal(error));
        dispatch(handleError(error));
    }
};
export const getPackagetreatments =
    ({ pageNumber = "" }) =>
        async (dispatch) => {
            try {
                dispatch({
                    type: GET_PACKAGETREATMENTS_STATED,
                });
                const queryParams = qs.parse(window.location.search.replace("?", ""));
                const query = qs.stringify(queryParams, {
                    encodeValuesOnly: true, // prettify url
                });

                const { data } = await api.get(`/packagetreatments?&${query}`);

                dispatch({
                    type: GET_PACKAGETREATMENTS,
                    payload: data,
                });
                dispatch({
                    type: GET_PACKAGETREATMENTS_ENDED,
                });
            } catch (error) {
                dispatch({
                    type: GET_PACKAGETREATMENTS_ENDED,
                });
                dispatch(handleErrorLocal(error));
                dispatch(handleError(error));
            }
        };
export const getPackagetreatment = (id) => async (dispatch) => {
    try {
        dispatch({
            type: GET_PACKAGETREATMENT_STATED,
        });
        const { data } = await api.get(`/packagetreatments/${id}`);

        dispatch({
            type: GET_PACKAGETREATMENT,
            payload: data,
        });
        dispatch({
            type: GET_PACKAGETREATMENT_ENDED,
        });
    } catch (error) {
        dispatch({
            type: GET_PACKAGETREATMENT_STATED,
        });
        dispatch(handleErrorLocal(error));
        dispatch(handleError(error));
    }
};
export const editPackagetreatment = (id, formData) => async (dispatch) => {
    try {
        dispatch({
            type: EDIT_PACKAGETREATMENT_STATED,
        });
        const { data } = await api.put(`/packagetreatments/${id}`, formData);
        dispatch({
            type: EDIT_PACKAGETREATMENT,
            payload: data,
        });
        dispatch({
            type: EDIT_PACKAGETREATMENT_ENDED,
        });
    } catch (error) {
        dispatch({
            type: EDIT_PACKAGETREATMENT_ENDED,
        });
        dispatch(handleErrorLocal(error));
        dispatch(handleError(error));
    }
};
export const getPackagetreatmentBySlug = (id) => async (dispatch) => {
    try {
        dispatch({
            type: GET_PACKAGETREATMENT_STATED,
        });
        const { data } = await api.get(`/packagetreatments/slug/${id}`);

        dispatch({
            type: GET_PACKAGETREATMENT,
            payload: data,
        });
        dispatch({
            type: GET_PACKAGETREATMENT_ENDED,
        });
    } catch (error) {
        dispatch({
            type: GET_PACKAGETREATMENT_STATED,
        });
        dispatch(handleErrorLocal(error));
        dispatch(handleError(error));
    }
};
export const deletePackagetreatment = (id) => async (dispatch) => {
    try {
        const { data } = await api.delete(`/packagetreatments/${id}`);
        dispatch(setAlert("Packagetreatment Deleted Successfully", "success"));
    } catch (error) {
        dispatch(handleErrorLocal(error));
        dispatch(handleError(error));
    }
};
export const getAllPackagetreatments =
    ({ term, value }) =>
        async (dispatch) => {
            try {
                dispatch({
                    type: GET_ALL_PACKAGETREATMENTS_STATED,
                });
                const { data } = await api.get(`/packagetreatments/all?term=${term}&value=${value}`);

                dispatch({
                    type: GET_ALL_PACKAGETREATMENTS,
                    payload: data,
                });
                dispatch({
                    type: GET_ALL_PACKAGETREATMENTS_ENDED,
                });
            } catch (error) {
                dispatch({
                    type: GET_ALL_PACKAGETREATMENTS_ENDED,
                });
                dispatch(handleErrorLocal(error));
                dispatch(handleError(error));
            }
        };

export const handleErrorLocal = () => async (dispatch) => { };
