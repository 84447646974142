import React, { useState } from 'react'
import Header from '../common/Header'
import Footer from '../common/Footer'
import Breadcrumb from '../common/Breadcrumb'
import { URI } from '../../domain/constant';
import { Formik, Form } from "formik";
import { TextArea, TextInput, SelectBox } from "../../components/Form/Form";
import * as Yup from "yup";
import renderHTML from 'react-render-html';
import WhyChooseUs from '../../components/whychooseus/WhyChooseUs';
import Testimonial from '../../components/testimonial/Testimonial';
import { useSelectAllPackagetreatment, useSinglePackagetreatmentBySlug } from '../../shared/hooks/UsePackagetreatment';
import { useCreateAppointment } from '../../shared/hooks/UseAppointment';

function SinglePackageTreatment({ match }) {

    const [data] = useSinglePackagetreatmentBySlug(match.params.slug);
    const { packagetreatment, packagetreatment_loading } = data;

    const [packagetreatment_data] = useSelectAllPackagetreatment();
    const { all_packagetreatments, all_packagetreatments_loading } = packagetreatment_data;

    const [showGreeting, setSetshowGreeting] = useState(false)

    const [appointment_data, addData] = useCreateAppointment();

    return (
        <>
            <Header />
            favicon.ico  {
                !packagetreatment_loading ? packagetreatment && (
                    <>
                        <Breadcrumb title={packagetreatment.name} />
                        <section className='section section-padding-02 '>
                            <div className='container'>
                                <div className='row'>
                                    <div className='col-md-8'>
                                        <div className="service-img">
                                            <img src={`${URI}${packagetreatment.image}`} alt="" />
                                        </div>
                                        <div className='service-content'>
                                            <div>
                                                <div className='pt-20'>
                                                    <h2>
                                                        <strong>{packagetreatment.name}</strong>
                                                    </h2>

                                                    <div>
                                                        <p>
                                                            {packagetreatment.description && renderHTML(packagetreatment.description)}
                                                        </p>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        {
                                            showGreeting ? (
                                                <div className='contact-form-area py-150 rpy-100'>
                                                    <div className="container">
                                                        <div className='contact-form-wrap wow fadeInUp delay-0-2s'>
                                                            <div className="success-message-box">
                                                                <div className="success-message">
                                                                    <h4>Thankyou!</h4>
                                                                    <p>Thankyou for Successfuly filling the form. Our Team will contact you regarding the same</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className="appointment-form appointment-form-bg">
                                                    <div className="contact-form-inside-box">
                                                        <div className="appointment-section-title mb-45 text-center">
                                                            <h3>Book an Appointment</h3>
                                                        </div>
                                                        <Formik
                                                            initialValues={{
                                                                name: "",
                                                                email: "",
                                                                phone: "",
                                                                service: "",
                                                                message: "",
                                                            }}
                                                            validationSchema={Yup.object({
                                                                name: Yup.string().required("Required"),
                                                                email: Yup.string().email('Invalid email').required('Required'),
                                                                phone: Yup.string().matches(/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/, 'Phone number is not valid').min(10, 'Must be exactly 10 digit').max(10, 'Must be exactly 10 digit').required("Required"),
                                                                message: Yup.string().required("Required"),
                                                            })}
                                                            onSubmit={async (values, { setSubmitting, resetForm }) => {
                                                                setSubmitting(true);
                                                                addData(values);
                                                                setSetshowGreeting(!showGreeting)
                                                                resetForm();
                                                                setSubmitting(false);
                                                            }}
                                                        >
                                                            {(formik) => {
                                                                console.log(formik);
                                                                return (
                                                                    <Form className="booking-from">
                                                                        <div className="row clearfix">
                                                                            <div className="col-sm-12">
                                                                                <div className="form-group">
                                                                                    <TextInput
                                                                                        type="text"
                                                                                        id="name"
                                                                                        name="name"
                                                                                        className="form-control"
                                                                                        defaultValue placeholder="Name" />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-sm-12">
                                                                                <div className="form-group">
                                                                                    <TextInput
                                                                                        type="text"
                                                                                        id="email"
                                                                                        name="email"
                                                                                        className="form-control"
                                                                                        defaultValue placeholder="Email" />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-sm-12">
                                                                                <div className="form-group">
                                                                                    <TextInput
                                                                                        type="text"
                                                                                        id="phone"
                                                                                        name="phone"
                                                                                        className="form-control"
                                                                                        defaultValue placeholder="Number" />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-sm-12">
                                                                                <div className="form-group">
                                                                                    <TextArea
                                                                                        type="text"
                                                                                        id="message"
                                                                                        name="message"
                                                                                        className="form-control"
                                                                                        defaultValue placeholder="Message"
                                                                                        col={12}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-body">
                                                                            <div className="mb-0 header-btn-appointment text-align-center mtb-1rem">
                                                                                <button
                                                                                    type="submit"
                                                                                    className="btn btn-primary btn-hover-dark w-100"
                                                                                >
                                                                                    Book an Appointment
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </Form>
                                                                );
                                                            }}
                                                        </Formik>
                                                    </div>
                                                </div>
                                            )
                                        }

                                    </div >
                                </div>
                            </div>
                        </section>

                    </>
                ) : (
                    <div></div>
                )
            }
            <WhyChooseUs />
            <Testimonial />
            <Footer />

        </>
    )
}

export default SinglePackageTreatment