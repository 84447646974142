import React from 'react';
import { useSelectAllCollection } from '../../shared/hooks/UseCollection';
import CollectionCard from '../../components/home/CollectionCard';
import { URI } from '../../domain/constant';
import renderHTML from 'react-render-html';

function Collection() {
  const [data] = useSelectAllCollection();
  const { all_collections, all_collections_loading } = data;
  return (
    <>
      <div className='section section-padding services-sec mt-3'>
        <div className='container'>
          {/* Features Wrapper Start */}
          <div className='features-wrapper row row-cols-1 row-cols-md-2 row-cols-lg-4 justify-content-center'>
            {all_collections &&
              all_collections.map((collection, index) => {
                if (index < 4) {
                  return (
                    <CollectionCard
                      image={`${URI}${collection.icon}`}
                      name={collection.name}
                      description={
                        collection.description &&
                        renderHTML(collection.description.substring(0, 150))
                      }
                      link={collection.slug}
                    />
                  );
                }
              })}
          </div>
        </div>
      </div>
    </>
  );
}

export default Collection;
