import React from 'react';
import { Link } from 'react-router-dom';

function About() {
  return (
    <div className='section section-padding-02 about-section'>
      {/* <img
        className='shape-1 movebounce-03'
        src='assets/images/shape/shape-5.webp'
        width={420}
        height={210}
        alt='Shape'
      /> */}
      <div className='container'>
        <div className='about-wrapper'>
          <div className='row justify-content-center align-items-center'>
            <div className='col-lg-6'>
              <div
                className='about-content'
                data-aos='fade-up'
                data-aos-delay={200}
              >
                <div className='section-title'>
                  {/* <h6 class="sub-title">Wellcom to Peerly</h6> */}
                  <h1 className='title'>
                    About <br /> Dr. Simrat Sandhu
                    <img
                      className='shape'
                      src='assets/images/shape/shape-15.webp'
                      alt='Shape'
                    />
                  </h1>
                  <h4>Cosmetic Physician</h4>
                </div>
                {/* Section Title End */}
                {/* <p className='text'>
                  Skincare protection is a small price to pay for healthy skin.
                </p> */}
                <p>
                  <strong>Dr Simrat Sandhu, Cosmetic Physician</strong> is
                  well-known for its skincare offerings. We are offering top of
                  the line facial, anti-acne, and anti-ageing treatments. Best
                  Cosmetologist in Amritsar Our ideology is to provide you with
                  a luxurious and serene experience with the use of state of the
                  art technology, comprehensive treatment and utmost care.
                </p>

                <p>
                  Dr Simrat Sandhu, Cosmetic Physician has done a series of
                  licenced courses to acquire further expertise and has also
                  done numerous training for the same.We offer all the
                  dermatology treatments tailored for your skin needs. Trust our
                  skin specialist in Amritsar for effective treatments and
                  radiant skin. Look no further! Our highly experienced and
                  top-rated dermatologists in Amritsar specialize in providing
                  comprehensive skin care solutions. She is passionate about
                  enhancing patients’ innate beauty. She believes in ‘less is
                  more and makes subtle aesthetic tweaks leading to natural and
                  beautiful results. Best Cosmetologist in Amritsar She abides
                  by the Golden Ratio of Beauty, that underlies our perception
                  of attractiveness.
                </p>

                <p>
                  We have recently established a reputation for offering skin
                  and hair treatments. We have a reputation for using
                  distinctive and propriety techniques and therapies and are
                  backed by some famous figures in the Indian beauty segment.
                </p>

                <p>
                  The maestro touch of Dr Simrat Sandhu, Cosmetic Physician has
                  helped enhance and transform thousands of lives. We offer
                  appropriate services in our clinics that will satisfy your
                  skin’s customised needs. Give yourself the best touch to feel
                  and admire your skin’s beauty.
                </p>
                <Link to='/about-us' className=' btn-primary btn-hover-dark'>
                  Read More
                </Link>
              </div>
              {/* About Image End */}
            </div>
            <div className='col-lg-6 col-md-8'>
              {/* About Image Start */}
              <div
                className='about-image'
                data-aos='fade-up'
                data-aos-delay={200}
              >
                <img
                  src='/assets/about/dr-image.jpg'
                  width={540}
                  height={565}
                  alt='About'
                />
              </div>
              {/* About Image End */}
            </div>
          </div>
        </div>
        {/* About Wrapper End */}
      </div>
    </div>
  );
}

export default About;
