import React, { useState } from 'react';
import Header from '../common/Header';
import Footer from '../common/Footer';
import Breadcrumb from '../common/Breadcrumb';
import {
  useSingleCollection,
  useSingleCollectionBySlug,
  useSelectAllCollection,
} from '../../shared/hooks/UseCollection';
import { URI } from '../../domain/constant';
import { Formik, Form } from 'formik';
import { TextArea, TextInput, SelectBox } from '../../components/Form/Form';
import * as Yup from 'yup';
import renderHTML from 'react-render-html';
import { useSelectAllService } from '../../shared/hooks/UseService';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import CollectionCard from './CollectionCard';
import WhyChooseUs from '../../components/whychooseus/WhyChooseUs';
import Testimonial from '../../components/testimonial/Testimonial';
// import { useCreateContact } from "../../shared/hooks/UseContact";
import { useCreateAppointment } from '../../shared/hooks/UseAppointment';
import ServiceCard from '../services/ServiceCard';
import { Helmet } from 'react-helmet';

function SingleCollection({ match }) {
  const [data] = useSingleCollectionBySlug(match.params.slug);
  const { collection, collection_loading } = data;

  const [collection_data] = useSelectAllCollection();
  const { all_collections, all_collections_loading } = collection_data;

  const [service_data] = useSelectAllService();
  const { all_services, all_services_loading } = service_data;

  console.log('all-services -', all_services);

  const [showGreeting, setSetshowGreeting] = useState(false);
  const [appointment_data, addData] = useCreateAppointment();

  // const [serviceData] = useSelectAllService();
  // const { all_services, all_services_loading } = serviceData

  return (
    <>
      <Header />
      {!collection_loading ? (
        collection && (
          <>
            <Breadcrumb title={collection.name} />

            <section className='collection-page section section-padding about-section'>
              <div className='container'>
                <div className='row'>
                  <div className='col-md-8'>
                    <div className='collection-featured-img'>
                      <img src={`${URI}${collection.image}`} alt='' />
                      {/* <img src={`/assets/images/test_2.jpg`} alt="" /> */}
                    </div>
                    <div className='service-top-section-content pt-18'>
                      <div className='service-top-section-heading'>
                        <h1>{collection.name} </h1>
                      </div>
                      <div className='service-top-section-para'>
                        <p>
                          {collection &&
                            collection.description &&
                            renderHTML(collection.description)}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-4'>
                    {showGreeting ? (
                      <div className='contact-form-area py-150 rpy-100'>
                        <div className='container'>
                          <div className='contact-form-wrap wow fadeInUp delay-0-2s'>
                            <div className='success-message-box'>
                              <div className='success-message'>
                                <h4>Thankyou!</h4>
                                <p>
                                  Thankyou for Successfuly filling the form. Our
                                  Team will contact you regarding the same
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className='appointment-form appointment-form-bg'>
                        <div className='contact-form-inside-box'>
                          <div className='appointment-section-title mb-45 text-center'>
                            <h3>Book an Appointment</h3>
                          </div>
                          <Formik
                            initialValues={{
                              name: '',
                              email: '',
                              phone: '',
                              service: '',
                              message: '',
                            }}
                            validationSchema={Yup.object({
                              name: Yup.string().required('Required'),
                              email: Yup.string()
                                .email('Invalid email')
                                .required('Required'),
                              phone: Yup.string()
                                .matches(
                                  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
                                  'Phone number is not valid'
                                )
                                .min(10, 'Must be exactly 10 digit')
                                .max(10, 'Must be exactly 10 digit')
                                .required('Required'),
                              message: Yup.string().required('Required'),
                            })}
                            onSubmit={async (
                              values,
                              { setSubmitting, resetForm }
                            ) => {
                              setSubmitting(true);
                              addData(values);
                              setSetshowGreeting(!showGreeting);
                              resetForm();
                              setSubmitting(false);
                            }}
                          >
                            {(formik) => {
                              console.log(formik);
                              return (
                                <Form className='booking-from'>
                                  <div className='row clearfix'>
                                    <div className='col-sm-12'>
                                      <div className='form-group'>
                                        <TextInput
                                          type='text'
                                          id='name'
                                          name='name'
                                          className='form-control'
                                          defaultValue
                                          placeholder='Name'
                                        />
                                      </div>
                                    </div>
                                    <div className='col-sm-12'>
                                      <div className='form-group'>
                                        <TextInput
                                          type='text'
                                          id='email'
                                          name='email'
                                          className='form-control'
                                          defaultValue
                                          placeholder='Email'
                                        />
                                      </div>
                                    </div>
                                    <div className='col-sm-12'>
                                      <div className='form-group'>
                                        <TextInput
                                          type='text'
                                          id='phone'
                                          name='phone'
                                          className='form-control'
                                          defaultValue
                                          placeholder='Number'
                                        />
                                      </div>
                                    </div>
                                    <div className='col-sm-12'>
                                      <div className='form-group'>
                                        <TextArea
                                          type='text'
                                          id='message'
                                          name='message'
                                          className='form-control'
                                          defaultValue
                                          placeholder='Message'
                                          col={12}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className='form-body'>
                                    <div className='mb-0 header-btn-appointment text-align-center mtb-1rem'>
                                      <button
                                        type='submit'
                                        className='btn btn-primary btn-hover-dark w-100'
                                      >
                                        Book an Appointment
                                      </button>
                                    </div>
                                  </div>
                                </Form>
                              );
                            }}
                          </Formik>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </section>
          </>
        )
      ) : (
        <div></div>
      )}

      <section className='related-collection section section-padding about-section'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-6'>
              <div className='related-collections-title'>
                <h2>Related Treatments</h2>
              </div>
            </div>
          </div>
          <div className='row'>
            {/* {
                            all_collections && all_collections.map((item, index) => {
                                return (
                                    <div className="col-md-4">
                                        <CollectionCard
                                            image={`${URI}${item.image}`}
                                            name={item.name}
                                            description={item.description && renderHTML(item.description.substring(0, 160))}
                                            link={`/collection/${item.slug}`}
                                        />
                                    </div>
                                )
                            })
                        } */}

            {!collection_loading && collection && (
              <>
                {all_services &&
                  all_services.filter(
                    (service) =>
                      service &&
                      service.service_collection &&
                      service.service_collection._id === collection._id
                  ) &&
                  all_services.filter(
                    (service) =>
                      service &&
                      service.service_collection &&
                      service.service_collection._id === collection._id
                  ).length > 0 && (
                    <div className='row'>
                      {all_services &&
                        all_services.map((service) => {
                          if (
                            service &&
                            service.service_collection &&
                            service.service_collection._id === collection._id
                          ) {
                            return (
                              <div className='col-md-4'>
                                <ServiceCard service={service} />
                              </div>
                            );
                          }
                        })}
                    </div>
                  )}
              </>
            )}
          </div>
        </div>
      </section>
      {/* <WhyChooseUs /> */}
      <Testimonial />
      <Footer />
    </>
  );
}

export default SingleCollection;
