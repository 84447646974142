import React from 'react';
import { URI } from '../../domain/constant';
import { useSelectAllBanner } from '../../shared/hooks/UseBanner';
import { Link } from 'react-router-dom';
import renderHTML from 'react-render-html';
import Slider from 'react-slick';
// import NextArrowComponent from "../slider/NextArrowComponent";
// import PreArrowComponent from "../slider/PreArrowComponent";

function Banner() {
  const [data] = useSelectAllBanner();
  const { all_banners, all_banners_loading } = data;
  const settings = {
    dots: true,
    // nav: true,
    autoplay: true,
    infinite: true,
    speed: 4500,
    slidesToShow: 1,
    slidesToScroll: 1,
    // nextArrow: <NextArrowComponent />,
    // prevArrow: <PreArrowComponent />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],

    // dots: true,
    // infinite: true,
    // slidesToShow: 3,
    // slidesToScroll: 1,
    // autoplay: true,
    // speed: 4000,
    // autoplaySpeed: 4000,
    // cssEase: "linear"
  };
  return (
    <section>
      <div className='banner-iamge'>
        <Slider {...settings}>
          {all_banners &&
            all_banners.map((banner) => {
              return <img src={`${URI}${banner.image}`} alt='banner' />;
            })}
        </Slider>
      </div>
    </section>
  );
}

export default Banner;
