import api from "../../domain/api";
import {
    GET_TECHNOLOGYS_STATED,
    GET_TECHNOLOGYS,
    GET_TECHNOLOGYS_ENDED,
    ADD_TECHNOLOGY_STATED,
    ADD_TECHNOLOGY,
    ADD_TECHNOLOGY_ENDED,
    EDIT_TECHNOLOGY_STATED,
    EDIT_TECHNOLOGY,
    EDIT_TECHNOLOGY_ENDED,
    GET_TECHNOLOGY_STATED,
    GET_TECHNOLOGY,
    GET_TECHNOLOGY_ENDED,
    GET_ALL_TECHNOLOGYS_STATED,
    GET_ALL_TECHNOLOGYS,
    GET_ALL_TECHNOLOGYS_ENDED,
} from "../types/technology_type";
import * as qs from "qs";
import { handleError } from "../../shared/handleError";
import { setAlert } from "./alert";

export const addTechnology = (formData) => async (dispatch, getState) => {
    try {
        dispatch({
            type: ADD_TECHNOLOGY_STATED,
        });
        const { data } = await api.post(`/technologys`, formData);
        dispatch({
            type: ADD_TECHNOLOGY,
            payload: data,
        });
        dispatch({
            type: ADD_TECHNOLOGY_ENDED,
        });
    } catch (error) {
        dispatch({
            type: ADD_TECHNOLOGY_ENDED,
        });
        dispatch(handleErrorLocal(error));
        dispatch(handleError(error));
    }
};
export const getTechnologys =
    ({ pageNumber = "" }) =>
        async (dispatch) => {
            try {
                dispatch({
                    type: GET_TECHNOLOGYS_STATED,
                });
                const queryParams = qs.parse(window.location.search.replace("?", ""));
                const query = qs.stringify(queryParams, {
                    encodeValuesOnly: true, // prettify url
                });

                const { data } = await api.get(`/technologys?&${query}`);

                dispatch({
                    type: GET_TECHNOLOGYS,
                    payload: data,
                });
                dispatch({
                    type: GET_TECHNOLOGYS_ENDED,
                });
            } catch (error) {
                dispatch({
                    type: GET_TECHNOLOGYS_ENDED,
                });
                dispatch(handleErrorLocal(error));
                dispatch(handleError(error));
            }
        };
export const getTechnology = (id) => async (dispatch) => {
    try {
        dispatch({
            type: GET_TECHNOLOGY_STATED,
        });
        const { data } = await api.get(`/technologys/${id}`);

        dispatch({
            type: GET_TECHNOLOGY,
            payload: data,
        });
        dispatch({
            type: GET_TECHNOLOGY_ENDED,
        });
    } catch (error) {
        dispatch({
            type: GET_TECHNOLOGY_STATED,
        });
        dispatch(handleErrorLocal(error));
        dispatch(handleError(error));
    }
};
export const editTechnology = (id, formData) => async (dispatch) => {
    try {
        dispatch({
            type: EDIT_TECHNOLOGY_STATED,
        });
        const { data } = await api.put(`/technologys/${id}`, formData);
        dispatch({
            type: EDIT_TECHNOLOGY,
            payload: data,
        });
        dispatch({
            type: EDIT_TECHNOLOGY_ENDED,
        });
    } catch (error) {
        dispatch({
            type: EDIT_TECHNOLOGY_ENDED,
        });
        dispatch(handleErrorLocal(error));
        dispatch(handleError(error));
    }
};
export const getTechnologyBySlug = (id) => async (dispatch) => {
    try {
        dispatch({
            type: GET_TECHNOLOGY_STATED,
        });
        const { data } = await api.get(`/technologys/slug/${id}`);

        dispatch({
            type: GET_TECHNOLOGY,
            payload: data,
        });
        dispatch({
            type: GET_TECHNOLOGY_ENDED,
        });
    } catch (error) {
        dispatch({
            type: GET_TECHNOLOGY_STATED,
        });
        dispatch(handleErrorLocal(error));
        dispatch(handleError(error));
    }
};
export const deleteTechnology = (id) => async (dispatch) => {
    try {
        const { data } = await api.delete(`/technologys/${id}`);
        dispatch(setAlert("Technology Deleted Successfully", "success"));
    } catch (error) {
        dispatch(handleErrorLocal(error));
        dispatch(handleError(error));
    }
};
export const getAllTechnologys =
    ({ term, value }) =>
        async (dispatch) => {
            try {
                dispatch({
                    type: GET_ALL_TECHNOLOGYS_STATED,
                });
                const { data } = await api.get(`/technologys/all?term=${term}&value=${value}`);

                dispatch({
                    type: GET_ALL_TECHNOLOGYS,
                    payload: data,
                });
                dispatch({
                    type: GET_ALL_TECHNOLOGYS_ENDED,
                });
            } catch (error) {
                dispatch({
                    type: GET_ALL_TECHNOLOGYS_ENDED,
                });
                dispatch(handleErrorLocal(error));
                dispatch(handleError(error));
            }
        };

export const handleErrorLocal = () => async (dispatch) => { };
