import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper';
import { useSelectAllService } from '../../shared/hooks/UseService';
import { Link } from 'react-router-dom';
import renderHTML from 'react-render-html';
import Slider from 'react-slick';
import { URI } from '../../domain/constant';
import ServiceCard from '../home/ServiceCard';
import NewServiceCard from '../home/ServiceCardTwo';

function ServiceSection() {
  const [data] = useSelectAllService();
  const { all_services, all_Services_loading } = data;

  var settings = {
    infinite: true,
    slidesToShow: 3,
    autoplay: true,
    arrow: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };
  return (
    <div
      className='section services-section'
      style={{ backgroundImage: 'url(assets/images/patan-bg.webp)' }}
    >
      {/* Services Wrapper Start */}
      <div className='services-wrapper'>
        {/* Services Main Content Start */}
        <div
          className='services-main-content'
          data-aos='fade-up'
          data-aos-delay={200}
        >
          <img
            className='shape-1 movebounce-02'
            src='assets/images/shape/shape-6.webp'
            alt='Shape'
          />
          <img
            className='shape-2 movebounce-03'
            src='assets/images/shape/shape-7.webp'
            alt='Shape'
          />
          <img
            className='shape-3'
            src='assets/images/shape/shape-8.webp'
            alt='Shape'
          />
          <div className='section-title text-center'>
            <h6 class='sub-title'>Service</h6>
            <h2 className='title'>OUR SERVICES</h2>
          </div>

          <div className='main-services-section'>
            <div className='row'>
              <Slider {...settings}>
                {all_services &&
                  all_services.map((service) => {
                    return (
                      <div className='col-md-4'>
                        <div className='new-service-card'>
                          <div className='new-service-card-inside-box'>
                            <div className='new-service-card-img'>
                              <img
                                src={`${URI}${service.image}`}
                                alt='service-img'
                              />
                            </div>
                            <div className='new-service-card-content'>
                              <Link to={`/service/${service.slug}`}>
                                <div className='new-service-card-title'>
                                  <h4>{service.name}</h4>
                                </div>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ServiceSection;
