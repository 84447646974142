import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelectAllCollection } from '../../../shared/hooks/UseCollection';
import { useSelectAllTechnology } from '../../../shared/hooks/UseTechnology';
import { useSelectAllPackagetreatment } from '../../../shared/hooks/UsePackagetreatment';
import { useSelectAllService } from '../../../shared/hooks/UseService';

function MobileMenu() {
  const [openmenu, setOpenmenu] = useState(null);
  const [dropdownmenu, setDropdownmenu] = useState(false);

  const [data] = useSelectAllCollection();
  const { all_collections, all_collections_loading } = data;

  const [techData] = useSelectAllTechnology();
  const { all_technologys, all_technologys_loading } = techData;

  const [packagetreatmentData] = useSelectAllPackagetreatment();
  const { all_packagetreatments, all_packagetreatments_loading } = packagetreatmentData

  const [serviceData] = useSelectAllService();
  const { all_services, all_services_loading } = serviceData

  const [openMobileMenu, setOpenMobileMenu] = useState(false)
  const [openMobileMenuPackage, setOpenMobileMenuPackage] = useState(false)

  return (
    <div className='offcanvas offcanvas-start' id='offcanvasNavbar'>
      <div className='offcanvas-header'>
        {/* Logo Start */}
        <div className='logo'>
          <Link TO='/'>
            <img
              src='/assets/images/logo.png'
              width={172}
              height={45}
              alt='Logo'
            />
          </Link>
        </div>
        {/* Logo End */}
        <button type='button' className='close' data-bs-dismiss='offcanvas'>
          <span />
          <span />
        </button>
      </div>
      <div className='offcanvas-body'>
        <div className='mobile-menu offcanvas-menu'>
          <ul className='navbar-menu'>
            <li>
              <Link to='/'>Home </Link>
            </li>
            <li>
              <Link to='/about-us'>About Us </Link>
            </li>

            <li>
              <a href="#">
                <p
                  onClick={() =>
                    setOpenMobileMenu(!openMobileMenu)
                  }
                >
                  Services
                </p>
                {openMobileMenu ? (
                  <div>
                    {
                      all_collections && all_collections.map((collection) => {
                        return (
                          <li>
                            <Link to={`/collection/${collection.slug}`}>{collection.name}</Link>
                            <span class='menu-icon'></span>
                            <ul class='sub-menu'>
                              {all_services && all_services.filter((service) => service && service.service_collection && service.service_collection._id === collection._id) && all_services.filter((service) => service && service.service_collection && service.service_collection._id === collection._id).length > 0 && (
                                <ul className="sub_menu">
                                  {all_services && all_services.map((service) => {
                                    if (service && service.service_collection && service.service_collection._id === collection._id) {
                                      return (
                                        <li>
                                          <Link to={`/service/${service.slug}`}>
                                            {service.name}
                                          </Link>
                                        </li>
                                      )
                                    }

                                  })}

                                </ul>
                              )}
                            </ul>
                          </li>
                        )
                      })
                    }
                  </div>
                ) : (
                  <div className="sample-text">
                  </div>
                )}
                <span class='menu-icon'></span>
                <ul class='sub-menu'>
                </ul>
              </a>
            </li>
            <li>
              <a href='#'>
                <p onClick={() => {
                  setOpenMobileMenuPackage(!openMobileMenuPackage)
                }}>
                  Packages
                </p>

                {
                  openMobileMenuPackage ? (
                    <div>
                      {
                        !all_packagetreatments_loading && all_packagetreatments && all_packagetreatments.map((packageitem) => {
                          return (
                            <li>
                              {/* <a href={packageitem.slug}>{packageitem.name}</a> */}
                              <Link to={`/packagetreatment/${packageitem.slug}`}>
                                {packageitem.name}
                              </Link>
                            </li>
                          )
                        })
                      }
                    </div>
                  ) : (
                    <div className="sample-text">
                    </div>
                  )

                }
              </a>
              <span class='menu-icon'></span>
              <ul class='sub-menu'>
              </ul>
            </li>
            <li>
              <a href='technologies'>Technologies</a>
              <span class='menu-icon'></span>
              <ul className='sub-menu'>
                {
                  !all_technologys_loading && all_technologys && all_technologys.map((item) => {
                    return (
                      <li>
                        {/* <Link to={item.slug}>{item.name}</Link> */}
                        <Link to={`/technology/${item.slug}`}>
                          {item.name}
                        </Link>
                      </li>
                    )
                  })
                }
              </ul>
            </li>
            <li>
              <Link to='/contact-us'>Contact Us </Link>
            </li>

          </ul>
        </div>
        <div className='mobi text-center'>
          <a
            href='#'
            className='btn appoin'
            data-bs-toggle='modal'
            data-bs-target='#staticBackdrop'
          >
            BOOK AN APPOINTMENT
          </a>
        </div>
      </div>
    </div>
  );
}

export default MobileMenu;
