import React from 'react'
import { Link } from 'react-router-dom/cjs/react-router-dom'
import { URI } from '../../domain/constant'
import renderHTML from 'react-render-html'

function ServiceCard({ service }) {
    return (
        <div className="service-card collection-card">
            <Link to={`/service/${service.slug}`}>
                <div className="service-card-inside-box">
                    <div className="service-card-img collection-card-img">
                        <img src={`${URI}${service.image}`} alt="" />
                    </div>
                    <div className="service-card-content collection-card-content pt-20">
                        <div className="service-card-title">
                            <h3>{service.name}</h3>
                        </div>
                        <div className="service-card-des">
                            <p>{service.description && renderHTML(service.description.substring(0, 160))}</p>
                        </div>
                    </div>
                </div>
            </Link>

        </div>
    )
}

export default ServiceCard