import React from 'react'
import { Link } from "react-router-dom";

function CollectionCard({ image, name, description, link }) {
    return (
        <div>
            <div className='col' data-aos='fade-up' data-aos-delay={200}>
                <Link to={`/collection/${link}`} >
                    <div className='single-feature text-center'>
                        <img src={image} alt='feature' />
                        <h4 className='title'>
                            <Link to='/'>{name}</Link>
                        </h4>
                        <p>
                            {description}
                        </p>
                        <div className='more'>
                            Read more
                        </div>
                    </div>
                </Link>
            </div>
        </div>
    )
}

export default CollectionCard