import React from 'react';

const OurDoctors = () => {
  return (
    <div className='our-doctors-sec'>
      <div className='container'>
        <div className='row'>
          <div className='col-md-12'>
            <div className='section-title text-center'>
              <h2 className='title'>OUR DOCTORS</h2>
            </div>
          </div>
        </div>
        <div className="row align-item-center">
          <div className='col-md-7'>
            <div className='about-content mt-5'>
              <p><strong>Dr Simrat Sandhu</strong> has done a series of licenced courses to acquire further expertise and has also done numerous training for the same. She is passionate about enhancing patients’ innate beauty. She believes in ‘less is more and makes subtle aesthetic tweaks leading to natural and beautiful results. Best Cosmetologist in Amritsar She abides by the Golden Ratio of Beauty, that underlies our perception of attractiveness.</p>

              <p>We have recently established a reputation for offering skin and hair treatments. We have a reputation for using distinctive and propriety techniques and therapies and are backed by some famous figures in the Indian beauty segment.</p>

              <p>The maestro touch of Dr Simrat Sandhu has helped enhance and transform thousands of lives. We offer appropriate services in our clinics that will satisfy your skin’s customised needs. Give yourself the best touch to feel and admire your skin’s beauty.</p>
            </div>
          </div>
          <div className='col-md-5'>
            <div className='doctor-card mt-5'>
              <div className='doctor-img'>
                <img src='/assets/about/Dr-Simrat-Sandhu.webp' alt='' />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurDoctors;

{
  /* <div
  className='section services-section'
  style={{ backgroundImage: 'url(assets/images/patan-bg.webp)' }}
>
  <div className='services-wrapper'>
    <div
      className='services-main-content'
      data-aos='fade-up'
      data-aos-delay={200}
    >
      <img
        className='shape-3'
        src='assets/images/shape/shape-8.webp'
        alt='Shape'
      />

      <div className='section-title text-center'>
        <h2 className='title'>OUR SERVICES</h2>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Ducimus,
          voluptatibus!
        </p>
      </div>
      <div className='services-active'>
        <div className='swiper-container'>
         
        </div>
      </div>
    </div>
    {/* Services Main Content End */
}
{
  /* Services Background Start */
}
// <div
//   className='services-background'
//   style={{
//     backgroundImage: 'url(assets/images/edited-imgs/skin-service-bg-2.png)',
//   }}
// />
{
  /* Services Background End */
}
// </div>
{
  /* Services Wrapper End */
}
//</div>; */}
