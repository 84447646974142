import { combineReducers } from "redux";
import alert from "./alert_reducer";
import auth from "./auth_reducer";
import { banner_reducer } from "./banner_reducer";
import { newsletter_reducer } from "./newsletter_reducer";
import { category_reducer } from "./category_reducer";
import { collection_reducer } from "./collection_reducer";
import { service_reducer } from "./service_reducer";
import { testimonial_reducer } from "./testimonial_reducer";
import { blogcategory_reducer } from "./blogcategory_reducer";
import { blog_reducer } from "./blog_reducer";
import { technology_reducer } from "./technology_reducer";
import { packagetreatment_reducer } from "./packagetreatment_reducer";
import { contact_reducer } from "./contact_reducer";
import { appointment_reducer } from "./apoointment_reducer";


export default combineReducers({
  alert,
  auth,
  banner: banner_reducer,
  category: category_reducer,
  collection: collection_reducer,
  service: service_reducer,
  newsletter: newsletter_reducer,
  testimonial: testimonial_reducer,
  blogcategory: blogcategory_reducer,
  blog: blog_reducer,
  technology: technology_reducer,
  packagetreatment: packagetreatment_reducer,
  contact: contact_reducer,
  appointment: appointment_reducer,
});

