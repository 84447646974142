import React from 'react'
import { Link } from 'react-router-dom/cjs/react-router-dom'

function BlogCard({ image, date, title, link }) {
    return (
        <div className='single-blog'>
            <div className='blgo-image'>
                <img
                    src={image}
                    width={314}
                    height={238}
                    alt='Blog'
                />
            </div>
            <div className='blgo-content'>
                <ul className='meta'>
                    <li>
                        <i className='fa fa-calendar' /> {date}
                    </li>
                    {/* <li>
        <a href="#"><i class="fa fa-user-o"></i> Mahesh</a>
      </li> */}
                </ul>
                <Link to={`/blog/${link}`}>
                    <h3 className='title'>
                        {title}
                    </h3>
                </Link>
            </div>
            <img
                src='assets/images/shape/shape-4.webp'
                className='shape'
                alt='Shape'
            />
        </div>
    )
}

export default BlogCard