import React from 'react'
import Breadcrumb from '../common/Breadcrumb';
import Footer from '../common/Footer';
import Header from '../common/Header';
import { useSelectAllPackagetreatment } from '../../shared/hooks/UsePackagetreatment';
import ServiceCard from '../services/ServiceCard';


function PackageTreatment() {

    const [data] = useSelectAllPackagetreatment();
    const { all_packagetreatments } = data;

    return (
        <div>
            <Header />
            <Breadcrumb title="Service Packages" />
            <div className='section section-padding services-sec'>
                <div className='container'>
                    {/* Features Wrapper Start */}
                    <div className="row mt-20">
                        {
                            all_packagetreatments && all_packagetreatments.map((item) => {
                                return (
                                    <div className="col-md-4">
                                        <ServiceCard service={item} />
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default PackageTreatment