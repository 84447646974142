import React from 'react';
import { useSelectAllCollection } from '../../shared/hooks/UseCollection';
import CollectionCard from '../../components/home/CollectionCard';
import { URI } from '../../domain/constant';
import renderHTML from 'react-render-html';
import Breadcrumb from '../common/Breadcrumb';
import Footer from '../common/Footer';
import Header from '../common/Header';
import { useSelectAllService } from '../../shared/hooks/UseService';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import ServiceCard from './ServiceCard';



function Services() {

  const [data] = useSelectAllService();
  const { all_services, all_services_loading } = data;
  return (
    <>
      <Header />
      <Breadcrumb title="Our Services" />
      <div className='section section-padding services-sec'>
        <div className='container'>
          {/* Features Wrapper Start */}
          <div className='row pt-20'>
            {
              all_services && all_services.map((service) => {
                return (
                  <div className="col-md-4">
                    <ServiceCard service={service} />
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>
      <Footer />
    </>

  );
}

export default Services;
