import React from 'react'
import Breadcrumb from '../common/Breadcrumb';
import Footer from '../common/Footer';
import Header from '../common/Header';
import CollectionCard from './CollectionCard';
import { useSelectAllCollection } from '../../shared/hooks/UseCollection';
import { URI } from '../../domain/constant';
import renderHTML from 'react-render-html';

function Collection() {
    const [data] = useSelectAllCollection();
    const { all_collections } = data;
    return (
        <>
            <Header />
            <Breadcrumb title={'Our Categories'} />
            <section className='collection-page section section-padding about-section'>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-10">
                            <div className="section-heading new-section-heading text-center section-title">
                                <h2 className='title'>Our Categories</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-20">
                        {
                            all_collections && all_collections.map((collection) => {
                                return (
                                    <div className="col-md-4">
                                        <CollectionCard
                                            image={`${URI}${collection.image}`}
                                            name={collection.name}
                                            description={collection.description && renderHTML(collection.description.substring(0, 160))}
                                            link={`/collection/${collection.slug}`}
                                        />
                                    </div>
                                )
                            })
                        }

                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default Collection