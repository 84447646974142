import React from 'react';
import Breadcrumb from '../common/Breadcrumb';
import Footer from '../common/Footer';
import Header from '../common/Header';
import OurDoctors from '../../components/ourdoctors/OurDoctors';
import { Helmet } from 'react-helmet';

function About() {
  return (
    <div>
      <Helmet>
        <meta charSet='utf-8' />
        <title itemProp='name'>
          Best Dermatologist in Amritsar – Expert Skin Care at Cosmetique by Dr
          Simrat Sandhu
        </title>
        <meta
          name='description'
          content='
Meet the best dermatologist in Amritsar at Cosmetique by Dr. Simrat Sandhu. Our expert team specialises in skin care, laser hair removal, PRP treatments, and so much more.'
        />
        <meta
          name='keywords'
          content='aesthetic clinic near me,
best skin doctor near me,
skin specialist doctor near me,
skin specialist near me,

'
        />

        <link rel='canonical' href='https://www.drsimratsandhu.in/about-us' />
        <meta
          property='og:title'
          content='  Best Dermatologist in Amritsar – Expert Skin Care at Cosmetique by Dr
          Simrat Sandhu'
        />
        <meta property='og:type' content='aesthetic clinic' />
        <meta
          property='og:url'
          content='https://www.drsimratsandhu.in/about-us'
        />
      </Helmet>
      <Header />
      <Breadcrumb title={'About us'} />

      <div className='section section-padding about-section'>
        <div className='container'>
          {/* About Wrapper Start */}
          <div className='about-wrapper'>
            <div className='row justify-content-center align-items-center'>
              <div className='col-lg-5 col-md-6'>
                {/* About Image Start */}
                <div
                  className='about-image'
                  data-aos='fade-right'
                  data-aos-delay={200}
                >
                  <img
                    src='/assets/about/Dr-Simrat-Sandhu.webp'
                    width={540}
                    height={565}
                    alt='About'
                  />
                </div>
                {/* About Image End */}
              </div>
              <div className='col-lg-7'>
                {/* About Image Start */}
                <div
                  className='about-content'
                  data-aos='fade-left'
                  data-aos-delay={200}
                >
                  {/* Section Title Start */}
                  <div className='section-title'>
                    <h1 className='title'>
                      Welcome to Cosmetique By
                      <br /> Dr. Simrat Sandhu{' '}
                    </h1>
                  </div>
                  {/* Section Title End */}

                  <p>
                    <strong> Dr Simrat Sandhu clinic </strong> is well-known for
                    its skincare offerings. We are offering top of the line
                    facial, anti-acne, and anti-ageing treatments. Best
                    Cosmetologist in Amritsar Our ideology is to provide you
                    with a luxurious and serene experience with the use of state
                    of the art technology, comprehensive treatment and utmost
                    care.
                  </p>

                  <p>
                    Dr Simrat Sandhu,Cosmetic Physician has done a series of
                    licenced courses to acquire further expertise and has also
                    done numerous training for the same. She is passionate about
                    enhancing patients’ innate beauty. She believes in ‘less is
                    more and makes subtle aesthetic tweaks leading to natural
                    and beautiful results. Best Cosmetologist in Amritsar She
                    abides by the Golden Ratio of Beauty, that underlies our
                    perception of attractiveness.
                  </p>

                  <p>
                    We have recently established a reputation for offering skin
                    and hair treatments. We have a reputation for using
                    distinctive and propriety techniques and therapies and are
                    backed by some famous figures in the Indian beauty segment.
                  </p>

                  <p>
                    The maestro touch of Dr Simrat Sandhu has helped enhance and
                    transform thousands of lives. We offer appropriate services
                    in our clinics that will satisfy your skin’s customised
                    needs. Give yourself the best touch to feel and admire your
                    skin’s beauty.
                  </p>

                  {/* <div className='proud'>
                    <h5>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Deleniti quibusdam magni fuga quos molestiae enim?
                    </h5>
                  </div> */}
                </div>
                {/* About Image End */}
              </div>
            </div>
          </div>
          {/* About Wrapper End */}
        </div>
        {/* <OurDoctors /> */}
      </div>
      {/* <section className='section section-padding about-section ptb-50'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-5'>
              <div className='doctor-iamge'>
                <img src='/assets/images/neha_image.jpeg' />
              </div>
            </div>
            <div className='col-md-7'>
              <div className='doctor-content'>
                <h2>MEET OUR FOUNDER: DR. NEHA THUKRAL </h2>
                <p>
                  <strong> Dr. Neha Thukral,</strong> Founder and Director of
                  Swakaaya Wellness Clinic, is a certified cosmetologist, hair
                  transplant surgeon, and dermatologist with a BPT and a diploma
                  in nutrition and health education in Delhi. Dr. Thukral is
                  also a member of the Indian Association of Physiotherapy.
                  Being the best dermatologist in Delhi, she provides highly
                  customized treatment for her patients. With her diagnosis and
                  treatment, thousands of patients are able to gain confidence.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* <section className="section section-padding about-section">
        <div className="container">
          <div className="row">
            <div className="col-md-7">
              <div className="doctor-content">
                <h2>MEET OUR CO-FOUNDER: DR. RISHI KAPAHI</h2>
                <p>
                  <strong>Dr. Rishi Kapahi,</strong> co-founder of Swakaaya
                  Wellness Clinic and a renowned doctor in New Delhi. Dr. Kapahi
                  enjoys working closely with his patients to create
                  personalized treatment plans aimed to improve, repair, and
                  maintain healthy glowing skin.
                </p>
              </div>
            </div>
            <div className="col-md-5">
              <div className="doctor-iamge">
                <img src="" />
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-md-12">
              <div className="help">
                <h4 className="text-center" style={{ fontWeight: 700 }}>
                  We help you to look and feel beautiful. Our motive is to make
                  you feel confident in your body. We are just a call away!
                </h4>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <Footer />
    </div>
  );
}

export default About;
