import React, { useEffect } from 'react';
// Import css files
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store/store';

import { loadUser } from './store/actions/auth';
import setAuthToken from './domain/setAuthToken';
import BeforeLoginRoutes from './shared/Routes/BeforeLoginRoutes';
import PrivateRoutes from './shared/Routes/PrivateRoutes';
import PageNotFound from './containers/notfound/PageNotFound';
import Home from './containers/home/Home';
import AOS from 'aos';
import Services from './containers/services/Services';


import About from './containers/about/About';
import Botox from './containers/services/Botox';

import Contact from './containers/contact/Contact';
import OurDoctors from './components/ourdoctors/OurDoctors';
import Blog from './containers/blog/Blog';
import Collection from './containers/colletions/Collection';
import SingleCollection from './containers/colletions/SingleCollection';
import SingleBlog from './containers/blog/SingleBlog';
import SingleService from './containers/services/SingleService';
import PackageTreatment from './containers/packagetreatments/PackageTreatment';
import SinglePackageTreatment from './containers/packagetreatments/SinglePackageTreatment';
import Technologys from './containers/technologys/Technologys';
import SingleTechnology from './containers/technologys/SingleTechnology';
function App() {
  useEffect(() => {
    //First we have to bring(get that) token, which is saved in local storage
    const token = localStorage.getItem('token');
    //then we will pass that token in setAuthToken method
    setAuthToken(token);
    store.dispatch(loadUser());
    AOS.init({
      once: true,
      duration: 1500,
    });
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Provider store={store}>
      <Router>
        <Switch>
          <Route exact path='/' component={Home} />
          <Route exact path='/collections' component={Collection} />
          <Route exact path='/collection/:slug' component={SingleCollection} />
          <Route exact path='/services' component={Services} />
          <Route exact path='/service/:slug' component={SingleService} />
          <Route exact path='/packages' component={PackageTreatment} />
          <Route exact path='/packagetreatment/:slug' component={SinglePackageTreatment} />
          <Route exact path='/technologies' component={Technologys} />
          <Route exact path='/technology/:id' component={SingleTechnology} />
          <Route exact path='/about-us' component={About} />
          <Route exact path='/contact-us' component={Contact} />




          <Route
            exact
            path='/blogs'
            component={Blog}
          />
          <Route
            exact
            path='/blog/:slug'
            component={SingleBlog}
          />
          <Route
            exact
            path='/our-services/botox'
            component={Botox}
          />
          <Route exact path='/our-doctors' component={OurDoctors} />
          <Route component={PageNotFound} />
        </Switch>
      </Router>
    </Provider>
  );
}

export default App;
