import React from 'react'
import Breadcrumb from '../common/Breadcrumb';
import Footer from '../common/Footer';
import Header from '../common/Header';
import { useSelectAllPackagetreatment } from '../../shared/hooks/UsePackagetreatment';
import ServiceCard from '../services/ServiceCard';
import { useSelectAllTechnology } from '../../shared/hooks/UseTechnology';

function Technologys() {

    const [data] = useSelectAllTechnology();
    const { all_technologys } = data;

    return (
        <>
            <Header />
            <Breadcrumb title="Technologies" />
            <div className='section section-padding services-sec'>
                <div className='container'>
                    {/* Features Wrapper Start */}
                    <div className="row mt-20">
                        {
                            all_technologys && all_technologys.map((item) => {
                                return (
                                    <div className="col-md-4">
                                        <ServiceCard service={item} />
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Technologys