import React, { useState } from 'react';
import Header from '../common/Header';
import Footer from '../common/Footer';
import Breadcrumb from '../common/Breadcrumb';
import { URI } from '../../domain/constant';
import { Formik, Form } from 'formik';
import { TextArea, TextInput, SelectBox } from '../../components/Form/Form';
import * as Yup from 'yup';
import renderHTML from 'react-render-html';
import {
  useSingleServiceBySlug,
  useSelectAllService,
} from '../../shared/hooks/UseService';
import WhyChooseUs from '../../components/whychooseus/WhyChooseUs';
import Testimonial from '../../components/testimonial/Testimonial';
import { useCreateAppointment } from '../../shared/hooks/UseAppointment';
import { Helmet } from 'react-helmet';

function SingleService({ match }) {
  const [single_data] = useSingleServiceBySlug(match.params.slug);
  const { service, service_loading } = single_data;

  const [service_data] = useSelectAllService();
  const { all_services, all_services_loading } = service_data;

  const [data, addData] = useCreateAppointment();

  const [showGreeting, setSetshowGreeting] = useState(false);
  return (
    <>
      <Header />
      {!service_loading ? (
        service && (
          <>
            <Breadcrumb title={service.name} />
            <Helmet>
              <meta charSet='utf-8' />
              <title itemProp='name'>{service.meta_title}</title>
              <meta name='description' content={service.meta_description} />
              <meta name='keywords' content={service.meta_keyword} />
              <meta property='og:title' content={service.meta_title} />
              <meta
                property='og:description'
                content={service.meta_description}
              />
              <link
                rel='canonical'
                href='https://www.drsimratsandhu.in/:slug'
              />
            </Helmet>
            <section className='section section-padding-02 '>
              <div className='container'>
                <div className='row'>
                  <div className='col-md-8'>
                    <div className='service-img'>
                      <img src={`${URI}${service.image}`} alt='' />
                    </div>
                    <div className='service-content mt-2'>
                      <div>
                        <div>
                          <h1>
                            <strong>{service.name}</strong>
                          </h1>

                          <div>
                            <p>
                              {service.card_description &&
                                renderHTML(service.card_description)}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-4'>
                    {showGreeting ? (
                      <div className='contact-form-area py-150 rpy-100'>
                        <div className='container'>
                          <div className='contact-form-wrap wow fadeInUp delay-0-2s'>
                            <div className='success-message-box'>
                              <div className='success-message'>
                                <h4>Thankyou!</h4>
                                <p>
                                  Thankyou for Successfuly filling the form. Our
                                  Team will contact you regarding the same
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className='appointment-form appointment-form-bg'>
                        <div className='contact-form-inside-box'>
                          <div className='appointment-section-title mb-45 text-center'>
                            <h3>Book an Appointment</h3>
                          </div>
                          <Formik
                            initialValues={{
                              name: '',
                              email: '',
                              phone: '',
                              service: '',
                              message: '',
                            }}
                            validationSchema={Yup.object({
                              name: Yup.string().required('Required'),
                              email: Yup.string()
                                .email('Invalid email')
                                .required('Required'),
                              phone: Yup.string()
                                .matches(
                                  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
                                  'Phone number is not valid'
                                )
                                .min(10, 'Must be exactly 10 digit')
                                .max(10, 'Must be exactly 10 digit')
                                .required('Required'),
                              message: Yup.string().required('Required'),
                            })}
                            onSubmit={async (
                              values,
                              { setSubmitting, resetForm }
                            ) => {
                              setSubmitting(true);
                              addData(values);
                              setSetshowGreeting(!showGreeting);
                              resetForm();
                              setSubmitting(false);
                            }}
                          >
                            {(formik) => {
                              console.log(formik);
                              return (
                                <Form className='booking-from'>
                                  <div className='row clearfix'>
                                    <div className='col-sm-12'>
                                      <div className='form-group'>
                                        <TextInput
                                          type='text'
                                          id='name'
                                          name='name'
                                          className='form-control'
                                          defaultValue
                                          placeholder='Name'
                                        />
                                      </div>
                                    </div>
                                    <div className='col-sm-12'>
                                      <div className='form-group'>
                                        <TextInput
                                          type='text'
                                          id='email'
                                          name='email'
                                          className='form-control'
                                          defaultValue
                                          placeholder='Email'
                                        />
                                      </div>
                                    </div>
                                    <div className='col-sm-12'>
                                      <div className='form-group'>
                                        <TextInput
                                          type='text'
                                          id='phone'
                                          name='phone'
                                          className='form-control'
                                          defaultValue
                                          placeholder='Phone'
                                        />
                                      </div>
                                    </div>
                                    <div className='col-sm-12'>
                                      <div className='form-group'>
                                        <TextArea
                                          type='text'
                                          id='message'
                                          name='message'
                                          className='form-control'
                                          defaultValue
                                          placeholder='Message'
                                          col={12}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className='form-body'>
                                    <div className='mb-0 header-btn-appointment text-align-center mtb-1rem'>
                                      <button
                                        type='submit'
                                        className='btn btn-primary btn-hover-dark w-100'
                                      >
                                        Book an Appointment
                                      </button>
                                    </div>
                                  </div>
                                </Form>
                              );
                            }}
                          </Formik>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </section>
            <Testimonial />
            <Footer />
          </>
        )
      ) : (
        <div></div>
      )}
    </>
  );
}

export default SingleService;
